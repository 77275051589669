import styled from 'styled-components';
import React, {useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {NavBarDropdown} from './NavBarDropdown';
import {Button} from '../Button/Button';
import {useNavBarDropdown} from '../../hooks/useNavBarDropdown';

const StyledNavBarItem = styled.div`
    position: relative;
    padding-bottom: 16px;
`;

const StyledNavLink = styled(NavLink)`
    font-family: var(--font-heading);
    font-weight: var(--fs-bold);
    font-size: var(--fs-navigation);
    line-height: 1.25;
    text-decoration: none;
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-dark)'};
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;
    
    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -5px;
        left: 0;
        border-radius: 2px;
        background-color: var(--color-secondary);
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
        }
    }
    
    &.active {
        &:after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    
    path {
        stroke: var(--color-secondary);
        stroke-width: 3;
    }
`;

export const NavBarItem = ({data, isLast, ...props}) => {
    const hasPages = (data?.type === "page-with-sub-pages" || data?.type === "elcid-page-with-sub-pages") && data?.pages?.length > 0;

    const ref = useRef(null);
    const { open, setOpen } = useNavBarDropdown(ref);

    return (
        <StyledNavBarItem ref={ref}>
            {isLast ? (
                <Button to={`/${data.slug}`} onClick={() => setOpen(false)}>
                    {data.title}
                </Button>
            ) : (
                <>
                    <StyledNavLink to={`/${data.slug}`} onClick={() => setOpen(false)} {...props}>
                        {data.title} {hasPages && <StyledChevron />}
                    </StyledNavLink>

                    {hasPages &&
                        <NavBarDropdown data={data} open={open} setOpen={setOpen} />
                    }
                </>
            )}
        </StyledNavBarItem>
    )
};