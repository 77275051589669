import styled from 'styled-components';
import React, {useEffect, useState} from 'react';
import {ReactComponent as Cross} from '../../assets/icons/cross.svg';
import {ReactComponent as Logo} from '../../assets/logos/logo-navbar-white.svg';
import {ReactComponent as Decoration} from '../../assets/illustrations/illustration.svg';
import {Link, useNavigation} from 'react-router-dom';
import {NavBarAccordion} from './NavBarAccordion';
import {useInElcidMode} from '../../hooks/useInElcidMode';
import logoElcid from '../../assets/logos/logo-navbar-elcid-white.png';
import {SwitchButton} from '../Button/Button';

const StyledNavBarHamburger = styled.div`
    position: fixed;
    top: 0;
    right: ${props => props.$isOpen ? '0' : '100%'};
    background-color: var(--color-primary);
    min-height: 100vh;
    height: 100%;

    @supports (min-height: -webkit-fill-available) {
        min-height: -webkit-fill-available;
    }
    
    width: 100%;
    z-index: 100;
    transition: right .2s ease;
`;

const Inner = styled.div`
    overflow-y: auto;
    padding: 0 20px 160px 20px;
    height: 100%;
    position: relative;
    z-index: 1;
`;

const Group = styled.div`
    margin-top: ${({$end}) => $end ? '10' : '14'}px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
`;

const StyledLogo = styled(Logo)`
    width: 182px;
    height: 50px;
`;

const StyledLogoElcid = styled.img`
    display: block;
    width: 168px;
    height: 50px;
    object-fit: contain;
`;

const StyledCross = styled(Cross)`
    width: 32px;
    height: 32px;
`;

const StyledDecoration = styled(Decoration)`
    position: absolute;
    right: -40px;
    bottom: 0;
    width: 300px;
    height: auto;
    transform: rotate(-20deg);
    
    path {
        fill: rgba(255, 255, 255, .05);
    }
`;

const NavBarHamburger = ({data, isOpen, closeMenu, showElcidSwitch}) => {
    const { inElcidMode } = useInElcidMode();
    const [activeId, setActiveId] = useState(null);

    const navigation = useNavigation();

    const closeMobileMenu = () => {
        closeMenu();
        setActiveId(null);
    }

    useEffect(() => {
        if(isOpen && navigation.state === "idle") {
            closeMobileMenu();
        }
    // eslint-disable-next-line
    }, [navigation]);


    const onShow = (item) => {
        setActiveId(activeId === item.id ? null : item.id)
    }

    return (
        <StyledNavBarHamburger $isOpen={isOpen}>
            <Inner>
                <Header>
                    <Link to={inElcidMode ? "/elcid" : "/"}>
                        {inElcidMode ? <StyledLogoElcid src={logoElcid} alt="" /> : <StyledLogo />}
                    </Link>

                    <StyledCross onClick={closeMobileMenu} />
                </Header>

                <Group $start>
                    {data?.filter(item => item.placement === 'start' && item.slug !== 'home' && item.slug !== 'elcid')?.map(item => (
                        <NavBarAccordion key={item.id} data={item} isActive={activeId === item.id} setActiveId={setActiveId} onShow={() => onShow(item)} />
                    ))}
                </Group>


                <Group $end>
                    {inElcidMode ? <NavBarAccordion data={{"title": "Contact", "slug": "elcid-contact"}} optionalOnClick={closeMobileMenu} /> : <NavBarAccordion data={{"title": "Contact", "slug": "contact"}} optionalOnClick={closeMobileMenu} />}
                    {data?.filter(item => item.placement === 'end' && item.slug !== 'home' && item.slug !== 'elcid')?.map((item, index, {length}) => (
                        <NavBarAccordion key={item.id} data={item} isActive={activeId === item.id} setActiveId={setActiveId} onShow={() => onShow(item)} isLast={!showElcidSwitch && length - 1 === index} />
                    ))}
                    {showElcidSwitch &&
                        <SwitchButton to={inElcidMode ? "/" : "/elcid"} $variant={inElcidMode ? "elcidOnPrimary" : "onPrimary"}>
                            {inElcidMode ? "SSR-Leiden" : "EL CID"}
                        </SwitchButton>
                    }
                </Group>
            </Inner>
            <StyledDecoration />
        </StyledNavBarHamburger>
    );
}

export default NavBarHamburger;