import styled, {css} from 'styled-components';

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

export const marginMixin = css`
    margin: ${({$mt, $mr, $mb, $ml}) => `${$mt ?? 0}px ${$mr ?? 0}px ${$mb ?? 0}px ${$ml ?? 0}px;`};
`;

export const roundedBorderBottomMixin = ({color, size}) => `
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: ${size};
        width: 100%;
        background-color: ${color};
        border-radius: ${size};
    }
`;

export const Container = styled.section`
    width: 100%;
    max-width: 1342px;
    margin: 0 auto;
    padding: 0 40px;

    @media screen and (max-width: 1024px) {
        max-width: 1302px;
        padding: 0 20px;
    }
`;

export const ExpandedContainer = styled.section`
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
`;

export const ColumnContainer = styled(Container)`
    max-width: 919px;

    @media screen and (max-width: 1024px) {
        max-width: 879px;
    }
`;

export const FooterContainer = styled(Container)`
    max-width: 1154px;

    @media screen and (max-width: 1024px) {
        max-width: 1114px;
    }
`;