import styled from 'styled-components';
import {ColumnContainer} from '../../components/Utils/Utils';

const StyledFullWidthImageParagraph = styled.div``;

const Image = styled.img`
    display: block;
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
`;

export const FullWidthImageParagraph = ({data}) => {
    if(!data?.imageUrl) { return null; }

    return (
        <StyledFullWidthImageParagraph>
            <ColumnContainer>
                <Image src={data?.imageUrl} alt="" />
            </ColumnContainer>
        </StyledFullWidthImageParagraph>
    );
}