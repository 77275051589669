import styled from 'styled-components';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {NavLink} from 'react-router-dom';
import React, {useRef} from 'react';
import {Button} from '../Button/Button';

const StyledAccordion = styled.section`
    user-select: none;
`;

const Item = styled.div`
    position: relative;
    padding-bottom: 20px;
    cursor: pointer;
    display: ${({$hasPages}) => $hasPages ? 'flex' : 'inline-flex'};
    align-items: center;
`;

const StyledNavLink = styled(NavLink)`
    font-family: var(--font-heading);
    font-weight: var(--fs-bold);
    font-size: var(--fs-navigation);
    line-height: 1.25;
    text-decoration: none;
    color: var(--color-white);
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-left: 5px;

    path {
        stroke: var(--color-secondary);
        stroke-width: 3;
    }
`;

const Content = styled.article`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
`;

const SubItem = styled(NavLink)`
    font-size: var(--fs-navigation-sub);
    text-decoration: none;
    color: var(--color-white);
    margin-left: 10px;
`;

export const NavBarAccordion = ({data, isActive, setActiveId, onShow, isLast, optionalOnClick}) => {
    const hasPages = (data?.type === "page-with-sub-pages" || data?.type === "elcid-page-with-sub-pages") && data?.pages?.length > 0;

    const ref = useRef();

    const toggleExpand = (e) => {
        // If clicked on the link itself, it shouldn't open the expandable section
        if(e.target === ref.current) {
            setActiveId(null);
        }
    }

    const handleClick = (e) => {
        onShow();
        toggleExpand(e);
    }

    return (
        <StyledAccordion>
            {isLast ? (
                <Button to={`/${data.slug}`} $variant="onPrimary">{data.title}</Button>
            ) : (
                <>
                    <Item onClick={(e) => hasPages ? handleClick(e) : undefined} $hasPages={hasPages}>
                        <StyledNavLink to={`/${data.slug}`} ref={ref} onClick={optionalOnClick}>{data.title}</StyledNavLink>
                        {hasPages && <StyledChevron $open={isActive} />}
                    </Item>
                    {(hasPages && isActive) &&
                        <Content>
                            {data.pages.map(item => (
                                <SubItem key={item.id} to={`${data.slug}/${item.slug}`}>{item.title}</SubItem>
                            ))}
                        </Content>
                    }
                </>
            )}
        </StyledAccordion>
    );
}