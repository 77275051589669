import styled from 'styled-components';
import {Heading2, Heading3} from '../../components/Heading/Heading';
import {Container} from '../../components/Utils/Utils';
import {Text} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import {parseTitle} from '../../helpers/parseTitle';

const StyledCollectionBlock = styled.section`
    padding-top: 95px;
    padding-bottom: 45px;
    
    @media screen and (max-width: 768px) {
        padding-top: 0;
        padding-bottom: 40px;
        margin-top: -160px;
    }

    @media screen and (max-width: 525px) {
        padding-bottom: 10px;
        margin-top: -200px;
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 60px;

    @media screen and (max-width: 1024px) {
        gap: 30px;
    }
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const TextBox = styled.div`
    @media screen and (min-width: 769px) {
        padding-top: 30px;
        align-self: flex-start;
        position: sticky;
        top: 120px;
        flex: 0 1 450px;
    }
`;

const CollectionBox = styled.div`
    flex: 1 1 750px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 90px;
    row-gap: 60px;

    @media screen and (max-width: 1280px) {
        gap: 60px;
        row-gap: 40px;
    }
    
    @media screen and (max-width: 1024px) {
        gap: 30px;
    }

    @media screen and (max-width: 768px) {
        row-gap: 0;
        flex-basis: unset;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100vw;
        margin-left: -20px;
        padding: 0 20px;
    }
`;

const Item = styled.div`
    @media screen and (min-width: 769px) {
        &:nth-child(2n-1) {
            position: relative;
            top: -180px;
        }
    }

    @media screen and (max-width: 768px) {
        width: 290px;
    }
`;

const OuterImage = styled.div`
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    aspect-ratio: 3 / 4;

    @supports not (aspect-ratio: 3 / 4) {
        overflow: hidden;
        padding-top: 133.33%;
    }
`;

const InnerImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: var(--box-shadow);

    @supports not (aspect-ratio: 3 / 4) {
        position: absolute;
        inset: 0;
    }
`;

export const CollectionBlock = ({data}) => {
    return (
        <StyledCollectionBlock>
            <Container>
                <Flex>
                    <TextBox>
                        <Heading2>{parseTitle(data.title)}</Heading2>
                    </TextBox>
                    <CollectionBox>
                        {data.blocks.map((item, index) => (
                            <Item key={`${data.id}-${index}`}>
                                {item.imageBlock &&
                                    <OuterImage>
                                        <InnerImage src={item.imageBlock} alt={item.titleBlock} />
                                    </OuterImage>
                                }
                                <Heading3 $mb={10}>{item.titleBlock}</Heading3>
                                <Text $mb={10}>{item.textBlock}</Text>
                                {(item.buttonUrlBlock && item.buttonTextBlock) && <Button to={item.buttonUrlBlock} $variant="secondary">{item.buttonTextBlock}</Button>}
                            </Item>
                        ))}
                    </CollectionBox>
                </Flex>
            </Container>
        </StyledCollectionBlock>
    );
}