import styled from 'styled-components';
import {ColumnContainer} from '../../components/Utils/Utils';

const StyledImageParagraph = styled.div``;

const Image = styled.img`
    display: block;
    object-fit: cover;
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 20px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
`;

export const ImageParagraph = ({data}) => {
    if(!data?.imageUrl) { return null; }

    return (
        <StyledImageParagraph>
            <ColumnContainer>
                <Image src={data?.imageUrl} alt="" />
            </ColumnContainer>
        </StyledImageParagraph>
    );
}