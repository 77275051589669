import styled from 'styled-components';
import {HTMLText} from '../../components/Text/Text';
import {ColumnContainer} from '../../components/Utils/Utils';

const StyledTextParagraph = styled.div``;

export const TextParagraph = ({data}) => {
    return (
        <StyledTextParagraph>
            <ColumnContainer>
                <HTMLText dangerouslySetInnerHTML={{__html: data?.text}} />
            </ColumnContainer>
        </StyledTextParagraph>
    );
}