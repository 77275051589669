import styled from 'styled-components';

export const StyledStatement = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;
    
    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

export const Content = styled.div`
    margin-top: 30px;
`;