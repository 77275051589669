import {json, useLoaderData} from 'react-router-dom';
import PageWithSubPages from '../PageWithSubPages/PageWithSubPages';
import ErrorPage from '../ErrorPage/ErrorPage';
import PageWithBlocks from '../PageWithBlocks/PageWithBlocks';
import PageWithParagraphs from '../PageWithParagraphs/PageWithParagraphs';
import BasicSubWebPage from '../BasicSubWebPage/BasicSubWebPage';
import NewsPage from '../NewsPage/NewsPage';
import NewsItem from '../NewsItem/NewsItem';
import PhotoAlbumPage from '../PhotoAlbumPage/PhotoAlbumPage';
import PhotoAlbum from '../PhotoAlbum/PhotoAlbum';
import FormSubWebPage from '../FormSubWebPage/FormSubWebPage';
import SchedulePage from '../SchedulePage/SchedulePage';
import ScheduleItem from '../ScheduleItem/ScheduleItem';

export async function dynamicPageLoader({request, params}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/webpages/${params["*"]}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    const data = await res.json();

    // Fetch news-items for paginated infinite scroll
    let newsPageData;
    if(data?.type === "news-page") {
        const newsPageRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/webpages/news-items?slug=${data?.slug}&page[size]=9&page[number]=1`, {
            signal: request.signal
        });

        if(!newsPageRes.ok) {
            throw new Response("Page not found", { status: 404 });
        }

        newsPageData = await newsPageRes.json();
    }

    // Fetch photo album data for paginated infinite scroll
    let photoAlbumData;
    if(data?.type === "photo-album") {
        const photoAlbumRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/webpages/photo-album-photos?slug=${data?.slug}&page[size]=9&page[number]=1`, {
            signal: request.signal
        });

        if(!photoAlbumRes.ok) {
            throw new Response("Page not found", { status: 404 });
        }

        photoAlbumData = await photoAlbumRes.json();
    }

    return {data, ...(newsPageData && {newsPageData}), ...(photoAlbumData && {photoAlbumData})};
}

export async function dynamicPageAction({request}) {
    const formData = await request.formData();

    const intent = formData.get('intent');

    if(intent === "sols") {
        const firstName = formData.get('firstName');
        const surname = formData.get('surname');
        const dateOfBirth = formData.get('dateOfBirth');
        const arrivalYear = formData.get('arrivalYear') ?? 10;
        const society = formData.get('society');
        const address = formData.get('address');
        const phoneNumber = formData.get('phoneNumber');
        const email = formData.get('email');
        const collaboration = formData.get('collaboration') ?? true;
        const token = formData.get('token');

        // Validate formData
        const errors = {};

        if (typeof firstName !== 'string' || !firstName.length > 0) {
            errors.firstName = 'Vul een voornaam in';
        }

        if (typeof surname !== 'string' || !surname.length > 0) {
            errors.surname = 'Vul een achternaam in';
        }

        if (typeof dateOfBirth !== 'string' || !dateOfBirth.length > 0) {
            errors.dateOfBirth = 'Vul een geboortedatum in';
        }

        if (typeof arrivalYear !== 'string' || !arrivalYear.length > 0) {
            errors.arrivalYear = 'Vul een aankomstjaar in';
        }

        if (typeof society !== 'string' || !society.length > 0) {
            errors.society = 'Vul een dispuut in';
        }

        if (typeof address !== 'string' || !address.length > 0) {
            errors.address = 'Vul een adres in';
        }

        if (typeof phoneNumber !== 'string' || !phoneNumber.length > 0) {
            errors.phoneNumber = 'Vul een telefoonnummer in';
        }

        if (typeof email !== 'string' || !email.includes('@') || !email.length > 0) {
            errors.email = 'Vul een geldig e-mailadres in';
        }

        // Return data if we have errors
        if (Object.keys(errors).length) {
            return {"errors": errors};
        }

        const body = {
            "firstName": firstName,
            "surname": surname,
            "dateOfBirth": dateOfBirth,
            "arrivalYear": Boolean(arrivalYear) ? JSON.parse(arrivalYear) : 0,
            "society": society,
            "address": address,
            "phoneNumber": phoneNumber,
            "email": email,
            "collaboration": JSON.parse(collaboration),
            "token": token
        }

        return fetch(`${process.env.REACT_APP_API_BASE_URL}/website/register`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            signal: request.signal
        }).then(response => {
            if(!response.ok) {
                return response.json().then((errorResponse) => {
                    throw errorResponse;
                });
            }

            return response.json();
        }).then(result => {
            return {"success": true};
        }).catch(error => {
            let formattedError = {};

            // Format the error object the same as client side validation
            if(error?.fields) {

                if (Array.isArray(error?.fields)) {
                    error?.fields?.forEach((err) => {
                        formattedError[err] = "Het veld is verplicht";
                    });
                } else {
                    Object.entries(error?.fields).forEach(entry => {
                        const [key, value] = entry;
                        formattedError[key] = value;
                    })
                }
            } else {
                formattedError.unknown = "Er is een fout opgetreden, probeer het opnieuw.";
            }

            return {
                "errors": formattedError
            };
        })
    }

    throw json(
        { message: "Invalid intent" },
        { status: 400 }
    );
}

const DynamicPage = () => {
    const {data, newsPageData, photoAlbumData} = useLoaderData();

    switch(data?.type) {
        case "page-with-blocks":
        case "elcid-page-with-blocks":
            return <PageWithBlocks data={data} />
        case "page-with-sub-pages":
        case "elcid-page-with-sub-pages":
            return <PageWithSubPages data={data} />
        case "basic-sub-web-page":
            return <BasicSubWebPage data={data} />
        case "form-sub-web-page":
            return <FormSubWebPage data={data} />
        case "page-with-paragraphs":
        case "elcid-page-with-paragraphs":
            return <PageWithParagraphs data={data} />
        case "news-page":
            return <NewsPage data={data} newsPageData={newsPageData} />
        case "news-item":
            return <NewsItem data={data} />
        case "photo-album-page":
            return <PhotoAlbumPage data={data} />
        case "photo-album":
            return <PhotoAlbum data={data} photoAlbumData={photoAlbumData} />
        case "elcid-schedule-page":
            return <SchedulePage data={data} />
        case "schedule-item-event":
            return <ScheduleItem data={data} />
        default:
            return <ErrorPage />
    }
}

export default DynamicPage;