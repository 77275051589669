import {Header} from '../../blocks/Header/Header';
import {BasicBlock} from '../../blocks/BasicBlock/BasicBlock';
import {CollectionBlock} from '../../blocks/CollectionBlock/CollectionBlock';
import {SponsorBlock} from '../../blocks/SponsorBlock/SponsorBlock';
import {ActionBlock} from '../../blocks/ActionBlock/ActionBlock';
import {RecentNews} from '../../blocks/RecentNews/RecentNews';
import {ScheduleBlock} from '../../blocks/ScheduleBlock/ScheduleBlock';

const blockComponents = {
    "slider-block": Header,
    "basic-block": BasicBlock,
    "block-collection-block": CollectionBlock,
    "sponsor-block": SponsorBlock,
    "action-block": ActionBlock,
    "recent-news-block": RecentNews,
    "schedule-block": ScheduleBlock,
}

// Display a block individually
const Block = ({ blockData, isHomePage }) => {
    // Only show RecentNewsBlock and ScheduleBlock on homepage
    if(!isHomePage && (blockData.type === "recent-news-block" || blockData.type === "schedule-block")) { return null; }

    // Prepare the component
    const BlockComponent = blockComponents[blockData.type]

    if (!BlockComponent) { return null }

    // Display the block
    return <BlockComponent data={blockData} />
}

// Display the list of blocks
export const Blocks = ({ data, isHomePage }) => {
    if(!data) { return null }

    return (
        <>
            {data.map((block, index) => (
                <Block key={`${block.type}${index}`} blockData={block} isHomePage={isHomePage} />
            ))}
        </>
    )
}