import {Heading3} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {Button} from '../Button/Button';
import styled from 'styled-components';
import {roundedBorderBottomMixin} from '../Utils/Utils';

const StyledSummaryItem = styled.section`
    position: relative;
    padding-bottom: 30px;
    
    &:not(:last-of-type) {
        margin-bottom: 30px;
    
        ${roundedBorderBottomMixin({color: 'rgba(var(--color-primary-rgb), .1)', size: '2px' })};
    }
    
    ${Heading3}, ${Text} { margin-bottom: 10px; }
    
    @media screen and (max-width: 1024px) {
        padding-bottom: 10px;
    }
`;

const OuterImage = styled.div`
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    aspect-ratio: 7 / 3;

    @supports not (aspect-ratio: 7 / 3) {
        overflow: hidden;
        padding-top: 42.86%;
    }
`;

const InnerImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: var(--box-shadow);

    @supports not (aspect-ratio: 7 / 3) {
        position: absolute;
        inset: 0;
    }
    
    @media screen and (max-width: 768px) {
        border-radius: 10px;
    }
`;

export const SummaryItem = ({data, url}) => {
    return (
        <StyledSummaryItem id={data.slug}>
            {data.imageUrl && (
                <OuterImage>
                    <InnerImage src={data.imageUrl} alt={data.title} />
                </OuterImage>
            )}
            <Heading3>{data.title}</Heading3>
            <Text>{data.text}</Text>
            <Button to={url} $variant="secondary">{data.buttonText}</Button>
        </StyledSummaryItem>
    );
}