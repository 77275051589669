import styled from 'styled-components';
import {textStyles} from '../Text/Text';
import calendar from '../../assets/icons/calendar.svg';

const StyledFormField = styled.div`
    margin-bottom: ${({$isLast}) => $isLast ? '30px' : '20px'};
    
    @media screen and (max-width: 768px) {
        margin-bottom: ${({$isLast}) => $isLast ? '22px' : '12px'};
    }
`;

const Options = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const Label = styled.label`
    font-size: var(--fs-label);
    color: var(--color-dark);
    display: inline-block;
    margin-bottom: 5px;
    opacity: .7;
`;

const RadioLabel = styled.label`
    ${textStyles};
    display: flex;
    align-items: center;
    gap: 4px;
`;

const RadioInput = styled.input`
    width: 18px;
    height: 18px;
    appearance: none;
    background-color: var(--color-white);
    margin: 0;
    color: #D9D9D9;
    border: 1px solid currentColor;
    border-radius: 50%;
    display: grid;
    place-content: center;

    &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: scale(0);
        transition: .12s ease-in-out transform;
        box-shadow: inset 8px 8px var(--color-secondary);
    }
    
    &:checked {
        background-color: var(--color-background-60);
        color: var(--color-secondary);
        border: 2px solid currentColor;
    }
    
    &:checked:before {
        transform: scale(1);
    }
`;

const Input = styled.input`
    ${textStyles};
    padding: 18px 25px;
    width: 100%;
    box-shadow: var(--box-shadow-input);
    border: 2px solid ${props => props?.$error ? 'var(--color-error)' : 'transparent'};
    outline: none;
    border-radius: 8px;
    max-height: 60px;
    background: var(--color-white);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    min-height: 60px;
    
    &:focus {
        border: 2px solid var(--color-primary);
    }
    
    @media screen and (max-width: 768px) {
        min-height: 47px;
        padding: 12px 18px;
    }
    
    &[type=date] {
        position: relative;

        ::-webkit-inner-spin-button, ::-webkit-calendar-picker-indicator {opacity: 0;}
        ::-webkit-date-and-time-value {text-align: left;}

        background-image: url(${calendar});
        background-repeat: no-repeat, repeat;
        background-position: right 23px top 50%, 0 0;
    }
`;

const TextArea = styled(Input).attrs({
    as: 'textarea'
})`
    resize: vertical;
    max-height: unset;
    min-height: 60px;
`;

const Error = styled.p`
    ${textStyles};
    color: var(--color-error);
    margin-top: 5px;
`;

export const FormField = ({id, label, type, name, options, error, children, ...props}) => {

    if(type === "radio") {
        return (
            <StyledFormField {...props}>
                <Label htmlFor={id}>{label}</Label>
                <Options>
                    {options.map((item, index) => (
                       <RadioLabel key={item.id} htmlFor={item.id}>
                           <RadioInput id={item.id} type="radio" name={name} value={item.value} defaultChecked={index === 0} />
                           {item.label}
                       </RadioLabel>
                    ))}
                </Options>
                {error && <Error>{error}</Error>}
            </StyledFormField>
        );
    }

    return (
        <StyledFormField {...props}>
            <Label htmlFor={id}>{label}</Label>
            {type === "textarea" ? (
                <TextArea id={id} type={type} name={name} $error={Boolean(error)} {...props} />
            ) : (
                <Input id={id} type={type} name={name} $error={Boolean(error)} {...props} />
            )}
            {error && <Error>{error}</Error>}
        </StyledFormField>
    );
}