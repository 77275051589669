import styled from 'styled-components';
import {Container} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import {Button} from '../../components/Button/Button';
import {PreviewItem} from '../../components/PreviewItem/PreviewItem';
import {Text} from '../../components/Text/Text';

const StyledScheduleBlock = styled.section`
    padding-top: 125px;
    padding-bottom: 125px;

    @media screen and (max-width: 1024px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media screen and (max-width: 525px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 30px;
    }
`;

const Box = styled.div`
    flex: ${({$heading}) => $heading ? '1 0 auto;' : '1 1 100%;'};
     
    ${({$button}) => $button && `
        display: flex;
        justify-content: flex-end;
    `};
    
    ${({$empty}) => $empty && `
        @media screen and (max-width: 768px) {
            display: none;
        }
    `};
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(293px, 1fr));
    gap: 30px;

    @media screen and (max-width: 979px) {
        overflow-x: auto;
        width: 100vw;
        margin: 0 -20px;
        padding: 0 20px;
    }

    @media screen and (max-width: 768px) {
        gap: 20px;
    }
`;

export const ScheduleBlock = ({data}) => {
    return (
        <StyledScheduleBlock>
            <Container>
                <Header>
                    <Box $empty />
                    <Box $heading>
                        <Heading2>{data.title}</Heading2>
                    </Box>
                    <Box $button>
                        <Button to={data.buttonUrl} $variant="elcidOnBackground">{data.buttonText}</Button>
                    </Box>
                </Header>

                {data?.items?.length > 0 ? (
                    <Grid>
                        {data.items?.map(item => (
                            <PreviewItem key={item.id} data={item} url={`/${data.buttonUrl}/${item.slug}`} />
                        ))}
                    </Grid>
                ) : (
                    <div>
                        <Text $center>Er zijn geen programmaonderdelen gevonden.</Text>
                    </div>
                )}
            </Container>
        </StyledScheduleBlock>
    );
}