import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as SwitchIcon} from '../../assets/icons/switch.svg';

const buttonVariants = (variant = 'primary') => ({
    primary: css`
        background-color: var(--color-primary-btn);
        color: var(--color-primary);

        &:hover {
            background-color: var(--color-primary-btn-hover);
        }
    `,
    secondary: css`
        background-color: transparent;
        color: var(--color-dark);
        padding-left: 0;
        padding-right: 0;
        text-align: left;

        &:hover {
            color: var(--color-primary);
        }
    `,
    onPrimary: css`
        background-color: var(--color-primary-btn-on-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-primary-btn-on-primary-hover);
        }
    `,
    elcidOnPrimary: css`
        background-color: var(--color-elcid-primary-btn-on-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-elcid-primary-btn-on-primary-hover);
        }
    `,
    onBackground: css`
        background-color: var(--color-primary-btn-on-background);
        color: var(--color-primary);

        &:hover {
            background-color: var(--color-primary-btn-on-background-hover);
        }
    `,
    elcidOnBackground: css`
        background-color: var(--color-elcid-primary-btn-on-background);
        color: var(--color-elcid-primary-btn-on-background-text);

        &:hover {
            background-color: var(--color-elcid-primary-btn-on-background-hover);
        }
    `,
    elcidSwitch: css`
        background-color: var(--color-elcid-btn-switch);
        color: var(--color-dark);

        &:hover {
            background-color: var(--color-elcid-btn-switch-hover);
        }
    `,
    elcidSwitchOnPrimary: css`
        background-color: var(--color-elcid-primary-btn-on-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-elcid-primary-btn-on-primary-hover);
        }
    `,
}[variant]);

export const buttonStyles = css`
    display: inline-block;
    border-radius: 26px;
    cursor: pointer;
    text-decoration: none;
    line-height: 1.5;
    max-width: fit-content;
    font-family: var(--font-heading);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-button);
    padding: 12px 24px;
    text-align: center;
    transition: transform .2s ease, background-color .2s ease;

    ${({$variant}) => buttonVariants($variant)};

    &:active {
        transform: translateY(3px)
    }
`;

const StyledLink = styled(Link)`
    ${buttonStyles};
`;

const ButtonWrapper = styled.div`
    text-align: right;
`;

const StyledSubmitButton = styled.button`
    ${buttonStyles};
    outline: none;
    border: none;
    
    &:disabled {
        pointer-events: none;
    }
`;

export const StyledArrowRight = styled(ArrowRight)`
    transform: rotate(-45deg);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    vertical-align: middle;
    margin-left: 10px;
    transition: transform .2s ease;
    
    path {
        stroke: var(--color-secondary);
    }
    
    ${StyledLink}:hover &, ${StyledSubmitButton}:hover &  {
        transform: rotate(0);
    }
`;

const SwitchButtonWrapper = styled.div`
    padding-bottom: 16px;
`;

export const StyledSwitchIcon = styled(SwitchIcon)`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    vertical-align: middle;
    margin-left: 10px;
    
    path {
        stroke: ${({$variant}) => $variant === "elcidSwitch" ? "var(--color-dark)" : $variant === "elcidSwitchOnPrimary" ? "var(--color-white)" : "var(--color-secondary)"};
    }
`;

export const Button = ({to = "/", hideArrow = false, children, ...props}) => {
    if(!to) { return null; }

    return (
        <StyledLink
            to={to}
            target={to.startsWith("http") ? '_blank' : '_self'}
            rel={to.startsWith("http") ? 'noreferrer' : undefined}
            {...props}
        >
            {children}
            {!hideArrow && <StyledArrowRight {...props} />}
        </StyledLink>
    );
}

export const SwitchButton = ({to = "/", children, ...props}) => {
    if(!to) { return null; }

    return (
        <SwitchButtonWrapper>
            <StyledLink to={to} {...props}>
                {children}
                <StyledSwitchIcon {...props} />
            </StyledLink>
        </SwitchButtonWrapper>
    );
}

export const SubmitButton = ({children, ...props}) => {
    return (
        <ButtonWrapper>
            <StyledSubmitButton {...props}>
                {children}
                <StyledArrowRight />
            </StyledSubmitButton>
        </ButtonWrapper>
    );
}

export const AcceptCookiesButton = ({children, ...props}) => {
    return (
        <StyledSubmitButton {...props}>
            {children}
            <StyledArrowRight {...props} />
        </StyledSubmitButton>
    );
}