import {ReactComponent as Instagram} from '../../assets/icons/instagram.svg';
import {ReactComponent as Facebook} from '../../assets/icons/facebook.svg';
import {ReactComponent as LinkedIn} from '../../assets/icons/linkedin.svg';
import {ReactComponent as Twitter} from '../../assets/icons/twitter.svg';
import {ReactComponent as AppStore} from '../../assets/icons/apple.svg';
import {ReactComponent as PlayStore} from '../../assets/icons/playstore.svg';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const icons = {
    "instagram": Instagram,
    "facebook": Facebook,
    "linkedin": LinkedIn,
    "twitter": Twitter,
    "appstore": AppStore,
    "playstore": PlayStore,
}

const StyledIconButton = styled(Link)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    text-decoration: none;
    background-color: ${({$onPrimary}) => $onPrimary ? 'var(--color-secondary);' : 'var(--color-primary);'};
    transition: background-color .2s ease;
    
    svg {
        path { fill: ${({$onPrimary}) => $onPrimary ? 'var(--color-primary);' : 'var(--color-white);'}; }
    }
    
    &:hover {
        background-color: ${({$onPrimary}) => $onPrimary ? 'var(--color-secondary-60);' : 'var(--color-primary-60);'};
    }
`;

export const IconButton = ({to, icon, ...props}) => {
    if(!to) { return null; }

    const Icon = icons[icon]

    if(!Icon) { return null; }

    return (
        <StyledIconButton to={to} target="_blank" rel="noreferrer" {...props}>
            <Icon />
        </StyledIconButton>
    );
}