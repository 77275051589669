import styled from 'styled-components';

const StyledPageImageOuter = styled.div`
    margin-top: 30px;
    position: relative;
    width: 100%;
    aspect-ratio: 7 / 3;

    @supports not (aspect-ratio: 7 / 3) {
        overflow: hidden;
        padding-top: 42.86%;
    }
    
    @media screen and (max-width: 768px) {
        width: 100vw;
        margin-left: -20px;
        margin-top: 20px;
    }
`;

const Inner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: var(--box-shadow);

    @supports not (aspect-ratio: 7 / 3) {
        position: absolute;
        inset: 0;
    }

    @media screen and (max-width: 768px) {
        border-radius: 0;
    }
`;

export const PageImage = ({...props}) => {
    return (
        <StyledPageImageOuter>
            <Inner {...props} />
        </StyledPageImageOuter>
    );
}