import styled from "styled-components";
import {Link} from 'react-router-dom';
import {Container} from '../Utils/Utils';
import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../assets/logos/logo-navbar.svg';
import {ReactComponent as Hamburger} from '../../assets/icons/hamburger.svg';
import {NavBarItem} from './NavBarItem';
import {useToggleMenu} from '../../hooks/useToggleMenu';
import {createPortal} from 'react-dom';
import NavBarHamburger from './NavBarHamburger';
import {SwitchButton} from '../Button/Button';
import {useInElcidMode} from '../../hooks/useInElcidMode';
import logoElcid from '../../assets/logos/logo-navbar-elcid.png';
import logoElcidWhite from '../../assets/logos/logo-navbar-elcid-white.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {useShouldInvertNavbar} from '../../hooks/useShouldInvertNavbar';

const StyledNavBar = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: ${({$shouldInvertNavbar, $scrolled}) => ($shouldInvertNavbar && !$scrolled) ? 'var(--color-primary)' : 'var(--color-background)'};
    ${({$scrolled}) => $scrolled && 'box-shadow: var(--box-shadow-navbar);'};
    transition: box-shadow .2s ease;
    ${({$shouldInvertNavbar}) => $shouldInvertNavbar && 'transition: background-color .2s ease;'};
    
    @media screen and (max-width: 1024px) {
        background-color: var(--color-background);
    }
`;

const Inner = styled.div`
    display: flex;
    gap: 30px;
    padding-top: 16px;
    
    @media screen and (max-width: 1280px) {
        gap: 20px;
    }
    
    @media screen and (max-width: 1024px) {
        padding-bottom: 16px;
        justify-content: space-between;
    } 
`;

const StyledLogo = styled(Logo)`
    width: 182px;
    height: 50px;
`;

const StyledLogoElcid = styled.img`
    display: block;
    width: 166px;
    height: 50px;
    object-fit: contain;
`;

const LinkBox = styled.nav`
    display: flex;
    gap: 40px;
    align-items: center;
    ${({$placement}) => $placement === "end" && 'margin-left: auto;'};
    min-height: 70px;

    @media screen and (max-width: 1280px) {
        gap: 20px;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const MobileBox = styled.div`
    display: none;
    align-self: center;

    @media screen and (max-width: 1024px) {
        display: block;
    }
`;

const StyledHamburger = styled(Hamburger)`
    width: 32px;
    height: 32px;

    path {
        stroke: var(--color-primary);
    }
`;

const NavBar = ({data}) => {
    // Change content in elcid mode
    const { inElcidMode } = useInElcidMode();
    const [headerData, setHeaderData] = useState(inElcidMode ? data?.header?.elcid : data?.header?.regular);
    const showElcidSwitch = data?.config?.elcidMode;

    useEffect(() => {
        setHeaderData(inElcidMode ? data?.header?.elcid : data?.header?.regular);
    }, [data, inElcidMode])

    // Menu and scroll behavior
    const { isOpen, openMenu, closeMenu } = useToggleMenu();

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 70) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Different menu style on elcid home page
    const {shouldInvertNavbar}  = useShouldInvertNavbar();
    const {width} = useWindowDimensions();

    return (
        <StyledNavBar $shouldInvertNavbar={shouldInvertNavbar} $scrolled={scroll}>
            <Container>
                <Inner>
                    <Link to={inElcidMode ? "/elcid" : "/"}>
                        {inElcidMode ? (
                            <StyledLogoElcid src={(shouldInvertNavbar && !scroll && width > 1024) ? logoElcidWhite : logoElcid} alt="" />
                        ) : (
                            <StyledLogo />
                        )}
                    </Link>

                    <LinkBox>
                        {headerData?.filter(item => item.placement === 'start' && item.slug !== 'home' && item.slug !== 'elcid')?.map(item => (
                            <NavBarItem key={item.id} data={item} $white={shouldInvertNavbar && !scroll} />
                        ))}
                    </LinkBox>

                    <LinkBox $placement="end">
                        {inElcidMode ? <NavBarItem data={{"title": "Contact", "slug": "elcid-contact"}} $white={shouldInvertNavbar && !scroll} /> : <NavBarItem data={{"title": "Contact", "slug": "contact"}} />}
                        {headerData?.filter(item => item.placement === 'end' && item.slug !== 'home' && item.slug !== 'elcid')?.map((item, index, {length}) => (
                            <NavBarItem key={item.id} data={item} isLast={!showElcidSwitch && length - 1 === index} $white={shouldInvertNavbar && !scroll} />
                        ))}
                        {showElcidSwitch &&
                            <SwitchButton to={inElcidMode ? "/" : "/elcid"} $variant={inElcidMode ? ((shouldInvertNavbar && !scroll) ? "elcidSwitchOnPrimary" : "elcidSwitch") : "primary"}>
                                {inElcidMode ? "SSR-Leiden" : "EL CID"}
                            </SwitchButton>
                        }
                    </LinkBox>

                    <MobileBox>
                        <StyledHamburger onClick={openMenu} />
                    </MobileBox>
                </Inner>
            </Container>

            {createPortal(
                <NavBarHamburger data={headerData} isOpen={isOpen} closeMenu={closeMenu} showElcidSwitch={showElcidSwitch} />, document.body
            )}
        </StyledNavBar>
    );
};

export default NavBar;