import styled from 'styled-components';
import Cookies from 'js-cookie';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Text} from '../Text/Text';
import {AcceptCookiesButton} from '../Button/Button';
import {useInElcidMode} from '../../hooks/useInElcidMode';
import {Heading4} from '../Heading/Heading';

const StyledCookieBanner = styled.div`
    position: fixed;
    left: 40px;
    bottom: 40px;
    width: 100%;
    max-width: 400px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    padding: 20px;
    z-index: 100;

    @media screen and (max-width: 1024px) {
        left: 20px;
        bottom: 20px;
    }

    @media screen and (max-width: 440px) {
        left: 0;
        bottom: 0;
        max-width: unset;
        border-radius: 20px 20px 0 0;
    }
`;

const StyledText = styled(Text)`
    font-size: 1.6rem;
    
    a {
        text-decoration: none;
        color: var(--color-primary);
        font-weight: var(--fw-bold);
        border-bottom: 3px solid ${({$elcid}) => $elcid ? 'var(--color-primary)' : 'var(--color-secondary)'};
        transition: .2s ease border-bottom;

        &:hover {
            border-color: var(--color-primary);
        }
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
`;

const CookieBanner = () => {
    const { inElcidMode } = useInElcidMode();

    const [acceptedCookies, setAcceptedCookies] = useState(Boolean(Cookies.get('ssr-leiden_cookies')));

    const handleAcceptation = () => {
        Cookies.set('ssr-leiden_cookies', true, {expires: 365, secure: true});
        setAcceptedCookies(true);
    }

    if(acceptedCookies) return null;

    return (
        <StyledCookieBanner>
            <Heading4>Cookies</Heading4>
            <StyledText $elcid={inElcidMode}>SSR-Leiden maakt gebruik van standaard functionele cookies om ervoor te zorgen dat de website werkt zoals verwacht. Met analytische cookies krijgen we inzicht in het gebruik van onze website. <Link to={inElcidMode ? "/elcid-cookiebeleid" : "/cookiebeleid"}>Lees meer</Link>.</StyledText>
            <ButtonWrapper>
                <AcceptCookiesButton onClick={handleAcceptation} $variant={inElcidMode ? "elcidOnBackground" : "primary"}>Begrepen</AcceptCookiesButton>
            </ButtonWrapper>
        </StyledCookieBanner>
    );
}

export default CookieBanner;