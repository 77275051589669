import {Helmet} from 'react-helmet-async';
import {Heading1} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {Container} from '../../components/Utils/Utils';
import {PageImage} from '../../components/PageImage/PageImage';
import {Paragraphs} from '../../components/Paragraphs/Paragraphs';

const StyledPageWithParagraphs = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const PageWithParagraphs = ({data}) => {
    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledPageWithParagraphs>
                <Container>
                    <Heading1 $center>{data.title}</Heading1>

                    {data.imageUrl && (
                        <PageImage src={data.imageUrl} alt={data.title} />
                    )}
                </Container>

                <Paragraphs data={data.paragraphs} />
            </StyledPageWithParagraphs>
        </>
    );
}

export default PageWithParagraphs;