import styled from 'styled-components';
import {Link as ReactScrollLink} from 'react-scroll';

const StyledReactScrollLink = styled(ReactScrollLink)`
    font-family: var(--font-heading);
    font-size: var(--fs-button);
    font-weight: var(--fw-semi-bold);
    color: var(--color-dark);
    display: block;
    cursor: pointer;
    
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    .dot {
        margin-right: 12px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        display: inline-block;
        background-color: rgba(var(--color-primary-rgb), .2);
        transition: width .2s ease;
    }

    &.sub-page--active {
        color: var(--color-primary);

        .dot {
            width: 48px;
            background-color: var(--color-primary);
        }
    }
`;

export const SpyLink = ({data}) => {
    return (
        <StyledReactScrollLink
            activeClass="sub-page--active"
            to={data.slug}
            spy={true}
            smooth={true}
            offset={-120}
            duration={500}
        >
            <span className="dot"></span>
            {data.title}
        </StyledReactScrollLink>
    );
}