import {useInElcidMode} from '../../hooks/useInElcidMode';
import {FooterContainer} from '../Utils/Utils';
import styled from 'styled-components';
import {ReactComponent as Logo} from '../../assets/logos/logo-footer.svg';
import {ReactComponent as Youmie} from '../../assets/logos/youmie.svg';
import logoElcid from '../../assets/logos/logo-footer-elcid.png';
import React, {useEffect, useState} from 'react';
import {Link, useMatch} from 'react-router-dom';
import {Heading4} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {IconButton} from '../IconButton/IconButton';
import {useIsHomePage} from '../../hooks/useIsHomePage';

const StyledFooter = styled.footer`
    margin-top: auto;
    padding: ${({$onHome, $onContact}) => $onHome || $onContact ? '205px 0 80px' : '80px 0'};
    background-color: ${({$elcid}) => $elcid ? 'rgba(var(--color-elcid-primary-rgb), .1)' : 'var(--color-background-60)'};
    
    @media screen and (max-width: 768px) {
        padding: ${({$onHome}) => $onHome ? '205px 0 80px' : '80px 0'};
    }
`;

const StyledLogo = styled(Logo)`
    width: 98px;
    height: 121px;
`;

const StyledLogoElcid = styled.img`
    display: block;
    width: 139px;
    height: 91px;
    object-fit: contain;
`;

const Main = styled.div`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
`;

const LogoBox = styled.div`
    flex: 1 0 auto;
    
    a {
        display: inline-block;
    }
`;

const NavBox = styled.div`
    flex: 2 1 255px;
`;

const ContactBox = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 18px;
`;

const AddressBox = styled.div`
    flex: 0 1 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    @media screen and (max-width: 768px) {
        flex: 1 1 200px;
    }
`;

const InnerNavBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 300px;
    gap: 30px;
    row-gap: 20px;
`;

const NavBoxItem = styled.div`
    
`;

const ContactApp = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ContactText = styled(Text)`
    color: var(--color-primary);
`;

const StyledNavigationLink = styled(Heading4).attrs({
    as: Link
})`
    text-decoration: none;
    position: relative;

    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -5px;
        left: 0;
        border-radius: 2px;
        background-color: var(--color-secondary);
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
        }
    }
`;

const StyledLink = styled(Link)`
    display: inline-block;
    color: inherit;
    font-weight: var(--fw-bold);
    text-decoration: none;
    position: relative;
    white-space: nowrap;

    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -2px;
        left: 0;
        border-radius: 2px;
        background-color: ${({$elcid}) => $elcid ? 'var(--color-primary)' : 'var(--color-secondary)'};
        transform: scaleX(1);
        transform-origin: bottom left;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform-origin: bottom right;
            transform: scaleX(0);
        }
    }
`;

const Sub = styled.div`
    margin-top: 55px;
    padding-top: 55px;
    border-top: 1px solid rgba(var(--color-primary-rgb), .08);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 30px;
        padding-top: 30px;
    }
`;

const SubText = styled(Text)`
    font-size: 1.4rem;
    
    a {
        text-decoration: none;
        color: inherit;
        
        &:hover {
            text-decoration: underline;
        }
    }
`;

const SubBox = styled.div`
    display: flex;
    align-items: center;
    gap: 60px;
    
    @media screen and (max-width: 1024px) {
        gap: 40px;
    }
    
    @media screen and (max-width: 525px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
`;

const PolicyLinks = styled.div`
    display: flex;
    gap: 40px;

    @media screen and (max-width: 1024px) {
        gap: 20px;
        flex-wrap: wrap;
    }
`;

const YoumieBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    
    svg {
        position: relative;
        top: 2px;
    }
`;

const IconButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const Footer = ({data}) => {
    const { inElcidMode } = useInElcidMode();
    const [navData, setNavData] = useState(inElcidMode ? data?.header?.elcid : data?.header?.regular);

    useEffect(() => {
        setNavData(inElcidMode ? data?.header?.elcid : data?.header?.regular);
    }, [data, inElcidMode])

    const { isHomePage } = useIsHomePage();
    const matchContact = useMatch("/contact");
    const matchElcidContact = useMatch("/elcid-contact");

    return (
        <StyledFooter $onContact={Boolean(matchContact) || Boolean(matchElcidContact)} $onHome={isHomePage}>
            <FooterContainer>
                <Main>
                    <LogoBox>
                        <Link to={inElcidMode ? "/elcid" : "/"}>
                            {inElcidMode ? <StyledLogoElcid src={logoElcid} alt="" /> : <StyledLogo />}
                        </Link>
                    </LogoBox>
                    <NavBox>
                        <InnerNavBox>
                            {navData?.filter(item => item.slug !== 'home' && item.slug !== 'elcid')?.map(item => (
                                <NavBoxItem key={item.id}>
                                    <StyledNavigationLink to={`/${item.slug}`}>{item.title}</StyledNavigationLink>
                                </NavBoxItem>
                            ))}
                        </InnerNavBox>
                    </NavBox>
                    <ContactBox>
                        <ContactText><StyledLink to="mailto:bestuur@ssr-leiden.nl" $elcid={inElcidMode}>bestuur@ssr-leiden.nl</StyledLink></ContactText>
                        <ContactText><StyledLink to="tel:0715120900" $elcid={inElcidMode}>071-5120900</StyledLink></ContactText>
                        <ContactApp>
                            <Text>Download de app</Text>
                            <IconButton icon="appstore" to="https://apps.apple.com/us/app/ssr-leiden/id6450697081" />
                            <IconButton icon="playstore" to="https://play.google.com/store/apps/details?id=nl.zooma.ssrleiden" />
                        </ContactApp>
                    </ContactBox>
                    <AddressBox>
                        <Text>SSR-Leiden<br/>Hogewoerd 108 <br/>2311 HT Leiden</Text>
                        <Text>KvK-nummer: 40445806</Text>
                    </AddressBox>
                </Main>
                <Sub>
                    <SubBox>
                        <IconButtons>
                            <IconButton icon="instagram" to="https://www.instagram.com/ssr_leiden/" />
                            <IconButton icon="facebook" to="https://www.facebook.com/SSRLeiden/" />
                            <IconButton icon="linkedin" to="https://www.linkedin.com/company/ssr-leiden" />
                            <IconButton icon="twitter" to="https://twitter.com/ssrleiden" />
                        </IconButtons>

                        <PolicyLinks>
                            <SubText><Link to={inElcidMode ? "/elcid-privacybeleid" : "/privacybeleid"}>Privacybeleid</Link></SubText>
                        </PolicyLinks>
                    </SubBox>

                    <SubBox>
                        <YoumieBlock>
                            <SubText>Ontwikkeld door</SubText>
                            <Link to="https://youmie.nl/" target="_blank" rel="noreferrer">
                                <Youmie />
                            </Link>
                        </YoumieBlock>

                        <SubText>&copy; SSR-Leiden {new Date().getFullYear()}</SubText>
                    </SubBox>
                </Sub>
            </FooterContainer>
        </StyledFooter>
    );
}