import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useInElcidMode = () => {
    const { pathname } = useLocation();
    const [inElcidMode, setInElcidMode] = useState(Boolean(pathname?.startsWith("/elcid")));

    useEffect(() => {
        setInElcidMode(Boolean(pathname?.startsWith("/elcid")));
    }, [pathname]);

    return { inElcidMode, setInElcidMode };
};