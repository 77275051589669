import styled, {keyframes} from 'styled-components';
import {ReactComponent as SpinnerIcon} from '../../assets/other/spinner.svg';

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const rotating = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const StyledSpinner = styled(SpinnerIcon)`
    width: 38px;
    height: 38px;
    animation: ${rotating} 2.5s linear infinite;
`;

export const Spinner = () => {
    return (
        <SpinnerWrapper>
            <StyledSpinner />
        </SpinnerWrapper>
    );
}