import styled from 'styled-components';
import {Heading3} from '../Heading/Heading';
import {buttonStyles, StyledArrowRight} from '../Button/Button';
import {Text} from '../Text/Text';
import {DateString} from '../DateString/DateString';
import {Link} from 'react-router-dom';

const StyledPreviewItem = styled(Link)`
    text-decoration: inherit;
    color: inherit;
    height: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

const OuterImage = styled.div`
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;
    overflow: hidden;
    border-radius: 20px;
    transform: translateZ(0); // Prevent iOS border-radius bug

    @supports not (aspect-ratio: 4 / 3) {
        overflow: hidden;
        padding-top: 75%;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const InnerImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: var(--box-shadow);

    @supports not (aspect-ratio: 4 / 3) {
        position: absolute;
        inset: 0;
    } 
    
    transition: transform .2s ease;

    ${StyledPreviewItem}:hover & {
        transform: scale(1.1);
    }
`;

const ButtonWrapper = styled.div`
    margin-top: auto;
    text-align: right;
`;

const FakeButton = styled.div`
    ${buttonStyles}
    
    ${StyledPreviewItem}:hover & {
        color: var(--color-primary);
        
        ${StyledArrowRight} {
            transform: rotate(0);
        }
    }
`;

export const PreviewItem = ({data, url, variant}) => {
    return (
        <StyledPreviewItem to={url ? url : data.slug}>
            {data.imageUrl && (
                <OuterImage>
                    <InnerImage src={data.imageUrl} alt={data.title} />
                </OuterImage>
            )}
            <DateString date={data?.displayDate} time={data.time} />
            <Heading3 $truncate $clamp={2}>{data.title}</Heading3>
            <Text $truncate $clamp={4}>{variant === "photo-album" ? data.description : data.introText}</Text>
            {(variant !== "photo-album") &&
                <ButtonWrapper>
                    <FakeButton $variant="secondary">{variant === "schedule" ? "Bekijk" : "Lees meer"} <StyledArrowRight /></FakeButton>
                </ButtonWrapper>
            }
        </StyledPreviewItem>
    );
}