import {useLoaderData} from 'react-router-dom';
import PageWithBlocks from '../PageWithBlocks/PageWithBlocks';

export async function homeLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/webpages/home`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    return await res.json()
}

const Home = () => {
    const data = useLoaderData();

    return <PageWithBlocks data={data} />
}

export default Home;