import styled from 'styled-components';
import {HTMLText,} from '../../components/Text/Text';
import {Container} from '../../components/Utils/Utils';
import {Heading3} from '../../components/Heading/Heading';

const StyledBasicBlock = styled.div`
    margin-top: -80px;
    background-color: var(--color-background-60);
    padding: 200px 0;
    
    @media screen and (max-width: 1024px) {
        margin-top: 0;
        padding: 80px 0 180px;
    }

    @media screen and (max-width: 768px) {
        padding: 80px 0 250px;
    }
    
    @media screen and (max-width: 525px) {
        padding: 50px 0 250px;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    
    @media screen and (max-width: 768px) {
        gap: 40px;
    }

    @media screen and (max-width: 525px) {
        flex-direction: column;
    }
`;

const Box = styled.div`
    flex: 1 1 0;
`;

const Image = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0 auto;
`;

const InnerBox = styled.div`
    max-width: 480px;
    margin: 0 auto;
`;

export const BasicBlock = ({data}) => {
    return (
        <StyledBasicBlock>
            <Container>
                <Flex>
                    <Box>
                        {data?.imageUrl && <Image src={data?.imageUrl} alt="" />}
                    </Box>
                    <Box>
                        <InnerBox>
                            {data.title && <Heading3 $mt={10}>{data.title}</Heading3>}
                            <HTMLText dangerouslySetInnerHTML={{__html: data?.text}} />
                        </InnerBox>
                    </Box>
                </Flex>
            </Container>
        </StyledBasicBlock>
    );
}