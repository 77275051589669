import styled from 'styled-components';
import {marginMixin, truncateMixin} from '../Utils/Utils';

export const Heading1 = styled.h1`
    color: ${({$white}) => $white ? 'var(--color-white);' : 'var(--color-dark);'};
    font-family: var(--font-heading);
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-1);
    line-height: 1.25;
    margin: 0;
    overflow-wrap: anywhere;
    ${({ $truncate }) => $truncate && truncateMixin};
    ${({ $center }) => $center && 'text-align: center;'};
    ${({ $mt, $mr, $mb, $ml }) => ($mt || $mr || $mb || $ml) && marginMixin};
        
    span.highlight {
        color: var(--color-primary);
    }
`;

export const Heading2 = styled(Heading1).attrs({
    as: 'h2'
})`
    font-size: var(--fs-heading-2);
    font-weight: var(--fw-semi-bold);
`;

export const Heading3 = styled(Heading1).attrs({
    as: 'h3'
})`
    font-size: var(--fs-heading-3);
    font-weight: var(--fw-semi-bold);
`;

export const Heading4 = styled(Heading1).attrs({
    as: 'h4'
})`
    font-size: var(--fs-heading-4);
    font-weight: var(--fw-semi-bold);
`;