import {Helmet} from 'react-helmet-async';
import {Heading1} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {Container} from '../../components/Utils/Utils';
import {PreviewItem} from '../../components/PreviewItem/PreviewItem';
import {Spinner} from '../../components/Spinner/Spinner';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {useState} from 'react';

const StyledNewsPage = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 30px;
    padding-bottom: 80px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        gap: 20px;
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: 1fr;
    }
`;

const SpinnerWrapper = styled.div``;

const NewsPage = ({data, newsPageData}) => {
    // Infinite scroll
    let count = newsPageData?.count;

    const [paginatedData, setPaginatedData] = useState(newsPageData?.items);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(count !== paginatedData.length);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(2);

    async function loadMore() {
        setLoading(true);

        try {
            const fetchedData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/webpages/news-items?slug=${data?.slug}&page[size]=9&page[number]=${page}`).then((res) => {
                if (!res.ok) {
                    throw new Response("Bad request", { status: 400 });
                }

                return res.json();
            })

            setPaginatedData([...paginatedData, ...fetchedData.items]);
            setPage(page + 1);

            if (count === [...paginatedData, ...fetchedData.items].length) setHasNextPage(false);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 0px 0px',
    });

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledNewsPage>
                <Container>
                    <Heading1 $center>{data.title}</Heading1>

                    <Grid>
                        {paginatedData?.map(item => (
                            <PreviewItem key={item.id} data={item} url={`/${data.slug}/${item.slug}`}/>
                        ))}
                    </Grid>

                    {(loading || hasNextPage) && (
                        <SpinnerWrapper ref={sentryRef}>
                            <Spinner />
                        </SpinnerWrapper>
                    )}
                </Container>
            </StyledNewsPage>
        </>
    );
}

export default NewsPage;