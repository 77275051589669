import {Helmet} from 'react-helmet-async';
import {Heading1} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {ColumnContainer} from '../../components/Utils/Utils';
import {DateString} from '../../components/DateString/DateString';
import {HTMLText} from '../../components/Text/Text';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledScheduleItem = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }

    @media screen and (max-width: 475px) {
        margin-top: 0;
    }
`;

const OuterImage = styled.div`
    margin-bottom: 50px;
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;

    @supports not (aspect-ratio: 4 / 3) {
        overflow: hidden;
        padding-top: 75%;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 475px) {
        width: 100vw;
        margin-left: -20px;
    }
`;

const InnerImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: var(--box-shadow);

    @supports not (aspect-ratio: 4 / 3) {
        position: absolute;
        inset: 0;
    }

    @media screen and (max-width: 475px) {
        border-radius: 0;
    }
`;

const ScheduleItem = ({data}) => {
    const { inElcidMode } = useInElcidMode();

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledScheduleItem>
                <ColumnContainer>
                    {data.imageUrl && (
                        <OuterImage>
                            <InnerImage src={data.imageUrl} alt={data.title} />
                        </OuterImage>
                    )}
                    <DateString date={data.displayDate} time={data.time} location={data.location} />
                    <Heading1 $mt={10} $mb={10}>{data.title}</Heading1>
                    <HTMLText dangerouslySetInnerHTML={{__html: data?.text}} $elcid={inElcidMode} />
                </ColumnContainer>
            </StyledScheduleItem>
        </>
    );
}

export default ScheduleItem;