import {Helmet} from 'react-helmet-async';
import {Heading1, Heading3} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {Container, roundedBorderBottomMixin} from '../../components/Utils/Utils';
import {SummaryItem} from '../../components/SummaryItem/SummaryItem';
import {SpyLink} from '../../components/SpyLink/SpyLink';
import {PageImage} from '../../components/PageImage/PageImage';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledPageWithSubPages = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 130px;
    margin-top: ${({$hasImage}) => $hasImage ? '60px' : '100px'};
    
    @media screen and (max-width: 1024px) {
        gap: 60px;
        margin-top: 60px;
    }

    @media screen and (max-width: 768px) {
        gap: 40px;
        margin-top: 40px;
    }

    @media screen and (max-width: 620px) {
        margin-top: 20px;
    }
`;

const Aside = styled.aside`
    align-self: flex-start;
    position: sticky;
    top: 120px;
    flex: 0 1 320px;

    @media screen and (max-width: 1024px) {
        flex: 0 1 300px;
    }

    @media screen and (max-width: 768px) {
        flex: 0 1 260px;
    }
    
    @media screen and (max-width: 620px) {
        display: none;
    }
`;

const AsideInner = styled.div`
    border-radius: 20px 20px 0 0;
    background-color: ${({$elcid}) => $elcid ? 'rgba(var(--color-elcid-primary-rgb), .1)' : 'var(--color-background-60)'};
    padding: 40px;

    @media screen and (max-width: 1024px) {
        padding: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`;

const AsideEnd = styled.div`
    padding-top: 100%;
    width: 100%;
    background-color: ${({$elcid}) => $elcid ? 'rgba(var(--color-elcid-primary-rgb), .1)' : 'var(--color-background-60)'};
    border-bottom-left-radius: 100%;
`;

const StyledHeading3 = styled(Heading3)`
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    
    ${roundedBorderBottomMixin({color: 'rgba(var(--color-primary-rgb), .2)', size: '2px' })};
`;

const Content = styled.div`
    flex: 1 1 425px;
`;

const PageWithSubPages = ({data}) => {
    const { inElcidMode } = useInElcidMode();

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledPageWithSubPages>
                <Container>
                    <Heading1 $center>{data.title}</Heading1>

                    {data.imageUrl && (
                        <PageImage src={data.imageUrl} alt={data.title} />
                    )}

                    <Flex $hasImage={Boolean(data.imageUrl)}>
                        <Aside>
                            <AsideInner $elcid={inElcidMode}>
                                <StyledHeading3>{data.title}</StyledHeading3>
                                {data.pages?.map(item => (
                                    <SpyLink key={item.id} data={item} />
                                ))}
                            </AsideInner>
                            <AsideEnd $elcid={inElcidMode} />
                        </Aside>
                        <Content>
                            {data.pages?.map(item => (
                                <SummaryItem key={item.id} data={item} url={`/${data.slug}/${item.slug}`}/>
                            ))}
                        </Content>
                    </Flex>
                </Container>
            </StyledPageWithSubPages>
        </>
    );
}

export default PageWithSubPages;