export const redirectPermanently = (location) => {
    return new Response("", {
        status: 301,
        headers: {
            Location: location,
        },
    })
};

export const oldUrls = [
    {
        oldPath: "/lid-worden",
        newPath: "/word-lid"
    },
    {
        oldPath: "/vereniging/lidmaatschap",
        newPath: "/"
    },
    {
        oldPath: "/vereniging/disputen/:slug",
        newPath: "/vereniging/disputen"
    },
    {
        oldPath: "/vereniging/commissies/:slug",
        newPath: "/vereniging/commissies"
    },
    {
        oldPath: "/adverteren/informatie-voor-adverteerders",
        newPath: "/adverteren"
    },
    {
        oldPath: "/sols/sols-activiteiten",
        newPath: "/sols/activiteiten"
    },
    {
        oldPath: "/sols/sols-subsidie-aanvragen",
        newPath: "/sols/subsidies"
    },
];