import {Helmet} from 'react-helmet-async';
import {Heading1, Heading2} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {Container} from '../../components/Utils/Utils';
import {PreviewItem} from '../../components/PreviewItem/PreviewItem';
import {Text} from '../../components/Text/Text';

const StyledSchedulePage = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const ScheduleGroup = styled.div`
    margin-top: 50px;
`;

const StyledHeading2 = styled(Heading2)`
    position: relative;
    padding-left: 32px;
    margin-bottom: 30px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 12px;
        background-color: var(--color-primary);
        border-radius: 6px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        gap: 20px;
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: 1fr;
    }
`;


const SchedulePage = ({data}) => {

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledSchedulePage>
                <Container>
                    <Heading1 $center>{data.title}</Heading1>

                    {data?.items?.length > 0 ? (
                        data?.items?.map(item => (
                            <ScheduleGroup key={item.id}>
                                <StyledHeading2>{item.title}</StyledHeading2>
                                {item?.events?.length > 0 ? (
                                    <Grid>
                                        {item?.events?.map(event => (
                                            <PreviewItem key={event.id} data={event} url={`/${data.slug}/${event.slug}`} variant="schedule" />
                                        ))}
                                    </Grid>
                                ) : (
                                    <Text>Er zijn geen evenementen gevonden.</Text>
                                )}

                            </ScheduleGroup>
                        ))
                    ) : (
                        <ScheduleGroup>
                            <Text $center>Er zijn geen programmaonderdelen gevonden.</Text>
                        </ScheduleGroup>
                    )}
                </Container>
            </StyledSchedulePage>
        </>
    );
}

export default SchedulePage;