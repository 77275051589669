import styled from 'styled-components';
import {ColumnContainer} from '../../components/Utils/Utils';

const StyledYouTubeParagraph = styled.div``;

const Outer = styled.div`
    position: relative;
    width: 100%;
    max-width: 640px;
    aspect-ratio: 16 / 9;
    background-color: var(--color-white);

    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: 56.25%;
    }
`;

const Inner = styled.iframe`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    border-radius: 10px;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
`;

export const YouTubeParagraph = ({data}) => {
    if(!data?.youTubeVideoUrl) { return null; }

    return (
        <StyledYouTubeParagraph>
            <ColumnContainer>
                <Outer>
                    <Inner src={data?.youTubeVideoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </Outer>
            </ColumnContainer>
        </StyledYouTubeParagraph>
    );
}