import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import Root, {rootLoader} from "./routes/Root/Root";
import Home, {homeLoader} from "./routes/Home/Home";
import ErrorPage, {ApiFailureFallback} from "./routes/ErrorPage/ErrorPage";
import DynamicPage, {dynamicPageAction, dynamicPageLoader} from "./routes/DynamicPage/DynamicPage";
import Contact, {contactAction} from "./routes/Contact/Contact";
import PrivacyStatement, {privacyStatementLoader} from "./routes/Statements/PrivacyStatement";
import CookieStatement, {cookieStatementLoader} from "./routes/Statements/CookieStatement";
import {oldUrls, redirectPermanently} from "./helpers/redirectPermanently";
import {mapUrlsToExternal} from "./helpers/redirectExternal";

function App() {

    const router = createBrowserRouter([
        {
            element: <Root />,
            loader: rootLoader,
            errorElement: <ApiFailureFallback />,
            children: [
                {
                    path: '/home?',
                    element: <Home />,
                    loader: homeLoader,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/contact',
                    element: <Contact />,
                    action: contactAction,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/elcid-contact',
                    element: <Contact />,
                    action: contactAction,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/privacybeleid',
                    element: <PrivacyStatement />,
                    loader: privacyStatementLoader,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/elcid-privacybeleid',
                    element: <PrivacyStatement />,
                    loader: privacyStatementLoader,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/cookiebeleid',
                    element: <CookieStatement />,
                    loader: cookieStatementLoader,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/elcid-cookiebeleid',
                    element: <CookieStatement />,
                    loader: cookieStatementLoader,
                    errorElement: <ErrorPage />
                },
                {
                    path: '/*',
                    element: <DynamicPage />,
                    loader: dynamicPageLoader,
                    action: dynamicPageAction,
                    errorElement: <ErrorPage />
                },
            ],
        },
        ...oldUrls.map(item => (
            {
                path: item.oldPath,
                loader: () => {
                    return redirectPermanently(item.newPath)
                },
                errorElement: <ErrorPage />
            }
        )),
        ...mapUrlsToExternal.map(item => (
            {
                path: item.initialPath,
                loader: () => {
                    return redirect(item.redirectPath)
                },
            }
        )),
    ]);

    return (
        <HelmetProvider>
            <RouterProvider router={router} />
        </HelmetProvider>
    );
}

export default App;