export const mapUrlsToExternal = [
    {
        initialPath: "/admin",
        redirectPath: "https://ssr-leiden.genkgo.app/"
    },
    {
        initialPath: "/bier",
        redirectPath: "https://ssr-leiden.mydealz.nl"
    },
    {
        initialPath: "/demo-dixo",
        redirectPath: "https://dd.ssr-leiden.nl"
    },
    {
        initialPath: "/fris",
        redirectPath: "https://ssr-leiden.mydealz.nl/"
    },
    {
        initialPath: "/game",
        redirectPath: "https://github.com/jordiboerboom/SSR_Game"
    },
    {
        initialPath: "/indedisco",
        redirectPath: "https://www.youtube.com/watch?v=LsNYgIDI7PI"
    },
    {
        initialPath: "/inschrijvingen",
        redirectPath: "https://form.jotform.com/232143633068048"
    },
    {
        initialPath: "/maaltijd",
        redirectPath: "https://maaltijd.ssr-leiden.nl/"
    },
    {
        initialPath: "/opwaarderen",
        redirectPath: "https://ssr-leiden.mydealz.nl/"
    },
    {
        initialPath: "/rooster",
        redirectPath: "https://roosters.ssr-leiden.nl/"
    },
    {
        initialPath: "/teller",
        redirectPath: "https://teller.ssr-leiden.nl/"
    },
];