import styled from 'styled-components';
import {Heading1, Heading3} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Button} from '../../components/Button/Button';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import {parseTitle} from '../../helpers/parseTitle';
import {ExpandedContainer} from '../../components/Utils/Utils';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledHeader = styled.header`
    ${({$elcid}) => $elcid && 'background-color:  var(--color-primary);'};
`;

const Flex = styled.div`
    display: flex;
    gap: 80px;

    @media screen and (max-width: 1280px) {
        gap: 40px;
    }
    
    @media screen and (max-width: 1024px) {
        gap: 80px;
        flex-direction: column-reverse;
    }
    
    @media screen and (max-width: 525px) {
        gap: 30px;
    }
`;

const TextBox = styled.article`
    align-self: center;
    flex: 1 1 45%;
    padding: 60px 0 100px max(calc((min(1920px, 100vw) - 1280px) / 2), 40px);
    margin-bottom: 80px;
    
    ${Heading1}, ${Text} { margin-bottom: 30px; }
    ${Heading3} { margin-bottom: 10px; }

    @media screen and (max-width: 1024px) {
        padding: 0 20px;
        margin-bottom: 80px;
    }
    
    @media screen and (max-width: 768px) {
        ${Heading1} { margin-bottom: 10px; }
    }
    
    @media screen and (max-width: 525px) {
        margin-bottom: 50px;
    }
`;

const ContentBox = styled.article`
    flex: 1 1 55%;
    width: 100%;
    position: relative;
    align-self: flex-end;
    min-width: 0;
    padding-top: 30px;
    
    @media screen and (max-width: 1024px) {
        padding-top: 0;
    }
`;

const SquareOuter = styled.div`
    position: relative;
    width: 100%;
    background-color: var(--color-background);
    overflow: hidden;
    padding-top: 75%;
    border-radius: 30px;

    @media screen and (max-width: 1920px) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media screen and (max-width: 1024px) {
        border-radius: 0;
    }
`;

const SquareInner = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    position: absolute;
    inset: 0;

    .swiper {
        width: 100%;
        height: 100%;
    }
    
    .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        opacity: 1;
        background-color: var(--color-white);
    }

    .swiper-pagination-bullet-active {
        background-color: var(--color-secondary);
    }
`;

const StyledImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const Header = ({data}) => {
    const { inElcidMode } = useInElcidMode();

    const customSwiperPagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <StyledHeader $elcid={inElcidMode}>
            <ExpandedContainer>
                <Flex>
                    <TextBox>
                        {data.subtitle && <Heading3 $white={inElcidMode}>{data.subtitle}</Heading3>}
                        <Heading1 $white={inElcidMode}>{parseTitle(data.title)}</Heading1>
                        <Text $white={inElcidMode}>{data.text}</Text>
                        {(data.buttonUrl && data.buttonText) && <Button to={data.buttonUrl} $variant={inElcidMode ? "elcidOnPrimary" : "primary"}>{data.buttonText}</Button>}
                    </TextBox>
                    <ContentBox>
                        <SquareOuter>
                            <SquareInner>
                                <Swiper
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: true,
                                    }}
                                    loop={true}
                                    pagination={customSwiperPagination}
                                    modules={[Autoplay, Pagination]}
                                    className="mySwiper"
                                >
                                    {data?.images?.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <StyledImage src={item} alt="" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </SquareInner>
                        </SquareOuter>
                    </ContentBox>
                </Flex>
            </ExpandedContainer>
        </StyledHeader>
    );
}