import styled from 'styled-components';
import {Heading4} from '../Heading/Heading';
import {ReactComponent as Dot} from '../../assets/icons/dot.svg';

const StyledDate = styled.div``;

const StyledHeading4 = styled(Heading4)`
    display: inline-block;
    opacity: .7;
    
    &:first-letter {
        text-transform: uppercase;
    }
`;

const StyledDot = styled(Dot)`
    display: inline-block;
    margin: 0 12px;
    transform: translateY(-2px);

    circle {
        fill: var(--color-elcid-primary);
    }
`;

export const DateString = ({date, time, location}) => {
    if(!date) { return null; }

    return (
        <StyledDate>
            <StyledHeading4>{date}</StyledHeading4>

            {time && (
                <>
                    <StyledDot />
                    <StyledHeading4>{time}</StyledHeading4>
                </>
            )}

            {location && (
                <>
                    <StyledDot />
                    <StyledHeading4>{location}</StyledHeading4>
                </>
            )}
        </StyledDate>
    );
}