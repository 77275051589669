import styled from 'styled-components';
import {ReactComponent as Decoration} from '../../assets/illustrations/illustration.svg';
import {Heading3} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {Button} from '../Button/Button';
import {Container} from '../Utils/Utils';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledBanner = styled.section`
    margin-bottom: -125px;
`;

const Wrapper = styled.div`
    background-color: var(--color-primary);
    border-radius: 30px;
    overflow: hidden;
    position: relative;
`;

const Flex = styled.article`
    position: relative;
    padding: 46px 50px;
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1; 
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: 525px) {
        padding: 30px;
    }
`;

const StyledHeading3 = styled(Heading3)`
    max-width: 330px;
    margin-bottom: 20px;
    line-height: 1.5;

    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const StyledText = styled(Text)`
    max-width: 730px;
`;

const ButtonWrapper = styled.div`
    flex-shrink: 0;
`;

const StyledDecoration = styled(Decoration)`
    position: absolute;
    right: 18%;
    bottom: -10px;
    width: 370px;
    height: auto;
    transform: rotate(-20deg);
    
    path {
        fill: rgba(255, 255, 255, .05);
    }
    
    @media screen and (max-width: 768px) {
        width: 200px;
        right: -25px;
    }
`;

export const Banner = () => {
    const { inElcidMode } = useInElcidMode();

    return (
        <StyledBanner>
            <Container>
                <Wrapper>
                    <Flex>
                        <div>
                            <StyledHeading3 $white>Benieuwd wat SSR allemaal te bieden heeft?</StyledHeading3>
                            <StyledText $white>SSR-Leiden behoort niet voor niks tot één van de grootste 5 studentenverenigingen in Leiden. Wij bieden jou de kans om jouw studententijd helemaal in te richten zoals je zelf wilt.</StyledText>
                        </div>

                        <ButtonWrapper>
                            <Button to="/word-lid" $variant={inElcidMode ? "elcidOnPrimary" : "onPrimary"}>Meer over lid worden</Button>
                        </ButtonWrapper>
                    </Flex>
                    <StyledDecoration />
                </Wrapper>
            </Container>
        </StyledBanner>
    );
}