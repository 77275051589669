import {TextParagraph} from '../../paragraphs/TextParagraph/TextParagraph';
import styled from 'styled-components';
import {ImageParagraph} from '../../paragraphs/ImageParagraph/ImageParagraph';
import {FullWidthImageParagraph} from '../../paragraphs/FullWidthImageParagraph/FullWidthImageParagraph';
import {YouTubeParagraph} from '../../paragraphs/YouTubeParagraph/YouTubeParagraph';
import {SponsorBlock} from '../../blocks/SponsorBlock/SponsorBlock';

const paragraphComponents = {
    "text": TextParagraph,
    "image": ImageParagraph,
    "youtube-video": YouTubeParagraph,
    "full-width-image": FullWidthImageParagraph,
    "sponsor": SponsorBlock,
}

// Display a paragraph individually
const Paragraph = ({ paragraphData }) => {
    // Prepare the component
    const ParagraphComponent = paragraphComponents[paragraphData.type]

    if (!ParagraphComponent) { return null }

    // Display the paragraph
    return <ParagraphComponent data={paragraphData} />
}

const StyledParagraphs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
`;

// Display the list of paragraphs
export const Paragraphs = ({ data }) => {
    if(!data) { return null }

    return (
        <StyledParagraphs>
            {data.map((paragraph, index) => (
                <Paragraph key={`${paragraph.type}${index}`} paragraphData={paragraph} />
            ))}
        </StyledParagraphs>
    )
}