import {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}
    * {
        box-sizing: border-box;
    }

    :root {
        /* Color properties */
        --color-white: #fff;
        --color-black: #000;

        --color-primary-40: #4B7DB9;
        --color-primary: ${({$elcid}) => $elcid ? "#ff5c39" : "#2e65a8"};
        --color-primary-rgb: ${({$elcid}) => $elcid ? "255, 92, 57" : "46, 101, 168"};
        --color-primary-60: ${({$elcid}) => $elcid ? "#ff7e63" : "#285C9C"};

        --color-secondary: ${({$elcid}) => $elcid ? "#a2e4b8" : "#8fb3e0"};
        --color-secondary-60: ${({$elcid}) => $elcid ? "#ccffe1" : "#74A0D8"};

        --color-dark: #333333;
        --color-dark-rgb: 51, 51, 51;

        --color-elcid-primary: #ff5c39;
        --color-elcid-primary-rgb: 255, 92, 57;
        --color-elcid-secondary: #a2e4b8;
        --color-elcid-secondary-rgb: 162, 228, 184;
        --color-elcid-background: #FEF2EF;

        --color-background: ${({$elcid}) => $elcid ? "#FBF9F8" : "#F8FDFF"};
        --color-background-60: ${({$elcid}) => $elcid ? "#FEF2EF" : "#EEF5FB"};
        --color-background-70: ${({$elcid}) => $elcid ? "#f8e9e5" : "#E6F0F8"};

        --color-primary-btn: #E4EEF6;
        --color-primary-btn-hover: #D0DFEE;
        --color-elcid-btn-switch: #E4E9EB;
        --color-elcid-btn-switch-hover: #CED5D8;

        --color-primary-btn-on-primary: #4B7DB9;
        --color-primary-btn-on-primary-hover: #376daf;
        --color-elcid-primary-btn-on-primary: #ff8469;
        --color-elcid-primary-btn-on-primary-hover: #ff9e88;

        --color-primary-btn-on-background: #d4e2f1;
        --color-primary-btn-on-background-hover: #c3d4e6;
        --color-elcid-primary-btn-on-background-text: #75D097;
        --color-elcid-primary-btn-on-background: #E0F5E6;
        --color-elcid-primary-btn-on-background-hover: #CEEFD8;

        --color-error: #ff7d7d;

        /* Font properties */
        --font-heading: 'Jost', sans-serif;
        --font-body: 'Outfit', sans-serif;

        --fw-regular: 400;
        --fw-semi-bold: 600;
        --fw-bold: 700;

        // Font sizes desktop (1024px)
        --fs-heading-1: 6rem;
        --fs-heading-2: 4.8rem;
        --fs-heading-3: 2.8rem;
        --fs-heading-4: 1.8rem;
        --fs-body: 1.8rem;
        --fs-button: 2rem;
        --fs-navigation: 1.8rem;
        --fs-navigation-sub: 1.8rem;
        --fs-label: 1.4rem;

        @media screen and (max-width: 1024px) {
            --fs-navigation-sub: 1.6rem;
        }

        @media screen and (max-width: 768px) {
            --fs-heading-1: 3.2rem;
            --fs-heading-2: 2.8rem;
            --fs-heading-3: 2.4rem;
            --fs-heading-4: 1.6rem;
            --fs-body: 1.6rem;
            --fs-button: 1.8rem;
        }

        /* Box shadow */
        --box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .1);
        --box-shadow-sponsor: 0 2px 40px 0 rgba(0, 0, 0, .05);
        --box-shadow-navbar: 0 1px 30px 0 rgba(var(--color-dark-rgb), .1);
        --box-shadow-navbar-dropdown: 0 2px 30px 0 rgba(0, 0, 0, .1);
        --box-shadow-input: 0 2px 5px 0 rgba(0, 0, 0, .07);
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font-body), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-background);
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-primary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;

        @media screen and (max-width: 1024px) {
            background: var(--color-secondary);
        }
    }

    .grecaptcha-badge {
        z-index: 99;
    }
`;

export default GlobalStyle;