import styled from 'styled-components';
import {Text} from '../../components/Text/Text';
import {Container} from '../../components/Utils/Utils';
import {Heading2} from '../../components/Heading/Heading';
import {Button} from '../../components/Button/Button';
import {parseTitle} from '../../helpers/parseTitle';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledActionBlock = styled.section`
    margin-top: -110px;
    background-color: var(--color-background-70);
    padding-top: 235px;
    padding-bottom: 160px;
    
    @media screen and (max-width: 1024px) {
        padding-top: 155px;
        padding-bottom: 80px;
    }
    
    @media screen and (max-width: 768px) {
        margin-top: -90px;
        padding-top: 135px;
    }

    @media screen and (max-width: 525px) {
        padding-top: 90px;
        padding-bottom: 50px;
    }
    
    @media screen and (max-width: 475px) {
        margin-top: -80px;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

const ImageBox = styled.div`
    flex: 1 1 0;
`;

const TextBox = styled.div`
    flex: 1 1 0;
`;

const InnerTextBox = styled.article`
    max-width: 510px;
    margin-left: auto;
    
    @media screen and (max-width: 768px) {
        max-width: unset;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    max-width: 480px;
    margin-right: 23%;
    margin-bottom: 17%;

    @media screen and (max-width: 768px) {
        max-width: unset;
    }
`;

const OuterImage = styled.div`
    ${({$foreground}) => $foreground ? `
        position: absolute;
        top: 30%;
        left: 30%;    
    ` : `
        position: relative;
    `};
    width: 100%;
    aspect-ratio: 4 / 3;

    @supports not (aspect-ratio: 4 / 3) {
        overflow: hidden;
        padding-top: 75%;
    }
`;

const InnerImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: var(--box-shadow);

    @supports not (aspect-ratio: 4 / 3) {
        position: absolute;
        inset: 0;
    }

    @media screen and (max-width: 768px) {
        border-radius: 10px;
    }
`;

export const ActionBlock = ({data}) => {
    const { inElcidMode } = useInElcidMode();

    return (
        <StyledActionBlock>
            <Container>
                <Flex>
                    <ImageBox>
                        <ImageWrapper>
                            <OuterImage>
                                {data?.backgroundImageUrl && <InnerImage src={data?.backgroundImageUrl} alt="" />}
                            </OuterImage>
                            <OuterImage $foreground>
                                {data?.foregroundImageUrl && <InnerImage src={data?.foregroundImageUrl} alt="" />}
                            </OuterImage>
                        </ImageWrapper>
                    </ImageBox>
                    <TextBox>
                        <InnerTextBox>
                            <Heading2 $mb={20}>{parseTitle(data.title)}</Heading2>
                            <Text $mb={30} $preWrap>{data.text}</Text>
                            <Button to={data.buttonUrl} $variant={inElcidMode ? "elcidOnBackground" : "onBackground"}>{data.buttonText}</Button>
                        </InnerTextBox>
                    </TextBox>
                </Flex>
            </Container>
        </StyledActionBlock>
    );
}