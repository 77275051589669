import {Outlet, redirect, ScrollRestoration, useLoaderData, useLocation, useNavigation} from 'react-router-dom';
import nProgress from 'nprogress';
import {useEffect} from 'react';
import styled from 'styled-components';
import GlobalStyle from '../../globalStyles';
import NavBar from '../../components/NavBar/NavBar';
import {useInElcidMode} from '../../hooks/useInElcidMode';
import {Footer} from '../../components/Footer/Footer';
import CookieBanner from '../../components/CookieBanner/CookieBanner';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

let shouldRedirectIfInElcidMode = true;

export async function rootLoader({request}) {
    const url = new URL(request.url);

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/header`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Something went wrong", { status: 404 });
    }

    const data = await res.json();

    // Redirect to elcid website if enabled in the CMS
    const isRedirectEnabled = data?.config?.elcidMode === true && data?.config?.redirectEnabled === true && Boolean(data?.config?.redirectUrl);
    const isHome = url?.pathname === "/" || url?.pathname.startsWith('/home')

    if(shouldRedirectIfInElcidMode && isRedirectEnabled && isHome) {
        shouldRedirectIfInElcidMode = false;
        return redirect(`/${data?.config?.redirectUrl}`);
    }

    return data;
}

const Root = () => {
    const data = useLoaderData();
    const { inElcidMode } = useInElcidMode();

    const location = useLocation();
    const navigation = useNavigation();

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();
    }, [location])

    return (
        <StyledLayout>
            <GlobalStyle $elcid={inElcidMode} />
            <ScrollRestoration />

            <NavBar data={data} />
            <Outlet />
            <Footer data={data} />
            <CookieBanner />
        </StyledLayout>
    );
}

export const ApiFailureFallbackLayout = ({children}) => {
    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollRestoration />
            {children}
        </StyledLayout>
    );
}

export default Root;