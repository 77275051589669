import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {textStyles} from '../Text/Text';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import {useRef} from 'react';
import {Transition} from 'react-transition-group';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledNavBarDropdown = styled.div`
    position: absolute;
    top: 100%;
    left: -20px;
    z-index: 99;
    padding: 8px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-navbar-dropdown);
    border-radius: 10px;

    width: max-content;
    height: auto;
    max-height: 50vh;
    overflow-y: auto;

    opacity: 0;
    transform: translateY(-10px);
     will-change: transform;
    transition: opacity .2s ease, transform .2s ease;

    &.entering { opacity: 1; transform: translateY(0); }
    &.entered { opacity: 1; transform: translateY(0); }
    &.exiting { opacity: 0; transform: translateY(-10px); }
    &.exited { opacity: 0; transform: translateY(-10px); }
`;

const StyledNavLink = styled(NavLink)`
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    text-decoration: none;
    padding: 12px 8px 12px 16px;
    ${textStyles};
    transition: background-color .2s ease;
    
    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
    
    &.active, &:hover {
        background-color: ${({$elcid}) => $elcid ? "rgba(var(--color-elcid-secondary-rgb), .1)" : "var(--color-background-60)"};
    }
`;

const StyledArrowRight = styled(ArrowRight)`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    opacity: 0;
    transition: .2s ease opacity;
    
    path {
        stroke: var(--color-secondary);
    }
    
    ${StyledNavLink}:hover & {
        opacity: 1;
    }
`;

export const NavBarDropdown = ({data, open, setOpen}) => {
    const nodeRef = useRef(null);
    const { inElcidMode } = useInElcidMode();

    return (
        <Transition nodeRef={nodeRef} in={open} timeout={200} unmountOnExit>
            {state => (
                <StyledNavBarDropdown ref={nodeRef} className={state} $open={open}>
                    {data.pages.map(item => (
                        <StyledNavLink
                            key={item.id}
                            onClick={() => setOpen(false)}
                            to={`${data.slug}/${item.slug}`}
                            target={item.linkType === 'external' ? '_blank' : '_self'}
                            rel={item.linkType === 'external' ? 'noreferrer' : undefined}
                            $elcid={inElcidMode}
                        >
                            {item.title}
                            <StyledArrowRight />
                        </StyledNavLink>
                    ))}
                </StyledNavBarDropdown>
            )}
        </Transition>
    )
}