import {useLoaderData} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Container} from '../../components/Utils/Utils';
import {Heading1} from '../../components/Heading/Heading';
import {TextParagraph} from '../../paragraphs/TextParagraph/TextParagraph';
import {Content, StyledStatement} from './Statement.elements';

export async function cookieStatementLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cookieStatement`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page not found", { status: 404 });
    }

    return await res.json()
}

const CookieStatement = () => {
    const data = useLoaderData();

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledStatement>
                <Container>
                    <Heading1 $center>{data.title}</Heading1>
                </Container>

                <Content>
                    <TextParagraph data={data} />
                </Content>
            </StyledStatement>
        </>
    );
}

export default CookieStatement;