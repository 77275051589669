import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/autoplay";
import {useRef} from 'react';
import {Container, ExpandedContainer} from '../../components/Utils/Utils';
import {Link} from 'react-router-dom';

const StyledSponsorBlock = styled.section`
    z-index: 1;
`;

const SwiperWrapper = styled.div`
    position: relative;

    .swiper-wrapper {
        transition-timing-function: linear;
        padding: 40px 0;
    }

    .swiper-slide {
        max-width: 240px;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .swiper-slide {
            max-width: 160px;
        }
    }

    @media screen and (max-width: 475px) {
        .swiper-slide {
            max-width: 130px;
        }
    }
`;

const StyledLink = styled(Link)`
    background-color: var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-sponsor);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    
    @media screen and (max-width: 768px) {
        border-radius: 10px;
        padding: 20px 0;
    }
`;

const Image = styled.img`
    display: block;
    max-width: 200px;
    width: 100%;
    height: 80px;
    object-fit: contain;

    @media screen and (max-width: 768px) {
        max-width: 150px;
        height: 60px;
    }
    
    @media screen and (max-width: 475px) {
        max-width: 110px;
        height: 45px;
    }
`;

const StyledContainer = styled(Container)`
    @media screen and (max-width: 1024px) {
        padding: 0;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns <= 5 ? $columns : 5}, 1fr);
    gap: 10px;
    padding: 40px 0;
    
    @media screen and (max-width: 1024px) {
        overflow-x: scroll;
        padding: 40px 20px;
    }
`;

const GridItem = styled.div`
    min-width: 188px;
`;

export const SponsorBlock = ({data}) => {
    const swiperElRef = useRef(null);

    let isSwiper = data?.sponsors.length >= 6;

    return (
        <StyledSponsorBlock>
                {isSwiper ? (
                    <ExpandedContainer>
                        <SwiperWrapper>
                            <Swiper
                                ref={swiperElRef}
                                spaceBetween={5}
                                loop={true}
                                slidesPerView={"auto"}
                                centeredSlides={true}
                                breakpoints={{
                                    476: {
                                        spaceBetween: 10,
                                    },
                                }}
                                threshold={10}
                                speed={3000}
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                            >
                                {data?.sponsors?.map(item => (
                                    <SwiperSlide key={item.id}>
                                        <StyledLink to={item?.url ?? "/"} target={item?.url?.startsWith("http") ? '_blank' : '_self'} rel={item?.url?.startsWith("http") ? 'noreferrer' : undefined}>
                                            <Image src={item?.imageUrl} alt={item.title} title={item.title} />
                                        </StyledLink>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </SwiperWrapper>
                    </ExpandedContainer>
                ) : (
                    <StyledContainer>
                        <Grid $columns={data?.sponsors?.length}>
                            {data?.sponsors?.map(item => (
                                <GridItem key={item.id}>
                                    <StyledLink to={item?.url ?? "/"} target={item?.url?.startsWith("http") ? '_blank' : '_self'} rel={item?.url?.startsWith("http") ? 'noreferrer' : undefined}>
                                        <Image src={item?.imageUrl} alt={item.title} title={item.title} />
                                    </StyledLink>
                                </GridItem>
                            ))}
                        </Grid>
                    </StyledContainer>
                )}
        </StyledSponsorBlock>
    );
}