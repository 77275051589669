import {Helmet} from 'react-helmet-async';
import {Heading1} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {ColumnContainer, Container} from '../../components/Utils/Utils';
import {Spinner} from '../../components/Spinner/Spinner';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {useState} from 'react';
import {Text} from '../../components/Text/Text';
import FsLightbox from "fslightbox-react";
import {DateString} from '../../components/DateString/DateString';

const StyledPhotoAlbum = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
    
    .fslightbox-slide-btn {
        border-radius: 50%;

        .fslightbox-svg-path {
            fill: var(--color-secondary);
        }
    }
`;

const Header = styled(ColumnContainer)`
    margin-bottom: 100px;
    
    @media screen and (max-width: 1024px) {
        margin-bottom: 60px;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 40px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 15px;
    margin-top: 30px;
    padding-bottom: 80px;

    @media screen and (max-width: 1024px) {
        gap: 10px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
    }
`;

const Item = styled.div`
    cursor: pointer;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    
    &:nth-of-type(9n-8), &:nth-of-type(9n-7), &:nth-of-type(9n-6) {
        grid-column: span 4;
    }

    &:nth-of-type(9n-5), &:nth-of-type(9n-4) {
        grid-column: span 6;
    }

    &:nth-of-type(9n-3), &:nth-of-type(9n-2), &:nth-of-type(9n-1), &:nth-of-type(9n) {
        grid-column: span 3;
    }

    @media screen and (max-width: 768px) {
        border-radius: 10px;
        
        &:nth-of-type(5n-4), &:nth-of-type(5n-3), &:nth-of-type(5n-2) {
            grid-column: span 2;
        }

        &:nth-of-type(5n-1), &:nth-of-type(5n) {
            grid-column: span 3;
        }
    }

    @media screen and (max-width: 475px) {
        border-radius: 5px;
        
        &:nth-of-type(3n-2), &:nth-of-type(3n-1) {
            grid-column: unset;
        }

        &:nth-of-type(3n) {
            grid-column: span 2;
        }
    }
`;

const ImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--color-white);

    @supports not (aspect-ratio: 16 / 9) {
        padding-top: 56.25%;
    }
`;

const ImageInner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        top: 0;
    }

    transition: transform .2s ease;

    ${Item}:hover & {
        transform: scale(1.1);
    }
`;

const SpinnerWrapper = styled.div``;

const PhotoAlbum = ({data, photoAlbumData}) => {
    // Infinite scroll
    let count = photoAlbumData?.count;

    const [paginatedData, setPaginatedData] = useState(photoAlbumData?.items?.filter(item => item.image !== null));
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(count !== paginatedData.length);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(2);

    async function loadMore() {
        setLoading(true);

        try {
            const fetchedData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/website/webpages/photo-album-photos?slug=${data?.slug}&page[size]=9&page[number]=${page}`).then((res) => {
                if (!res.ok) {
                    throw new Response("Bad request", { status: 400 });
                }

                return res.json();
            })

            let fetchedDataWithImage = fetchedData?.items?.filter(item => item.image !== null);

            setPaginatedData([...paginatedData, ...fetchedDataWithImage]);
            setPage(page + 1);

            if (count === [...paginatedData, ...fetchedData.items].length) setHasNextPage(false);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 0px 0px',
    });

    // Lightbox
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1,
    });

    const openLightbox = (index) => {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index + 1,
        });
    };

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledPhotoAlbum>
                <Header>
                    <DateString date={data?.displayDate} />
                    <Heading1 $mt={10} $mb={10}>{data.title}</Heading1>
                    <Text>{data.description}</Text>
                </Header>

                <Container>
                    {paginatedData?.length === 0 ? (
                        <Text>Dit fotoalbum bevat nog geen foto's. Kom op een later moment terug.</Text>
                    ) : (
                        <>
                            <Grid>
                                {paginatedData?.map((item, index) => (
                                    <Item key={item.id} onClick={() => openLightbox(index)}>
                                        <ImageOuter>
                                            <ImageInner src={item.image} alt="" />
                                        </ImageOuter>
                                    </Item>
                                ))}
                            </Grid>

                            {(loading || hasNextPage) && (
                                <SpinnerWrapper ref={sentryRef}>
                                    <Spinner />
                                </SpinnerWrapper>
                            )}
                        </>
                    )}
                </Container>

                <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={paginatedData?.map((item) => item.image)}
                    slide={lightboxController.slide}
                    type="image"
                    key={paginatedData?.length}
                />
            </StyledPhotoAlbum>
        </>
    );
}

export default PhotoAlbum;