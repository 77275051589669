import {Helmet} from 'react-helmet-async';
import {Blocks} from '../../components/Blocks/Blocks';
import {Banner} from '../../components/Banner/Banner';
import {useIsHomePage} from '../../hooks/useIsHomePage';

const PageWithBlocks = ({data}) => {
    const { isHomePage } = useIsHomePage();

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <Blocks data={data.blocks} isHomePage={isHomePage} />

            {isHomePage && <Banner />}
        </>
    );
}

export default PageWithBlocks;