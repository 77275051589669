import {Helmet} from 'react-helmet-async';
import {Heading1} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {Container} from '../../components/Utils/Utils';
import {PreviewItem} from '../../components/PreviewItem/PreviewItem';

const StyledPhotoAlbumPage = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 30px;
    padding-bottom: 80px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        gap: 20px;
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: 1fr;
    }
`;

const PhotoAlbumPage = ({data}) => {
    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledPhotoAlbumPage>
                <Container>
                    <Heading1 $center>{data.title}</Heading1>

                    <Grid>
                        {data?.photoAlbums?.map(item => (
                            <PreviewItem key={item.id} data={item} url={`/${data.slug}/${item.slug}`} variant="photo-album" />
                        ))}
                    </Grid>
                </Container>
            </StyledPhotoAlbumPage>
        </>
    );
}

export default PhotoAlbumPage;