import {Helmet} from 'react-helmet-async';
import {Heading1, Heading3} from '../../components/Heading/Heading';
import styled from 'styled-components';
import {ColumnContainer} from '../../components/Utils/Utils';
import {Text, textStyles} from '../../components/Text/Text';
import {Form, useActionData, useLocation, useNavigation} from 'react-router-dom';
import {FormField} from '../../components/FormField/FormField';
import {SubmitButton} from '../../components/Button/Button';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import React, {useEffect, useState} from 'react';

const StyledFormSubWebPage = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const Content = styled.div`
    margin-top: 30px;
    
    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

const FormWrapper = styled.article`
    padding: 40px;
    border-radius: 20px;
    background-color: var(--color-background-60);
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
        padding: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 25px 25px 30px;
        margin-top: 30px;
    }
`;

const StyledHeading3 = styled(Heading3)`
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        margin-bottom: 12px;
    }
`;

const Error = styled.p`
    ${textStyles};
    color: var(--color-error);
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        margin-bottom: 22px;
    }
`;

const FormSubWebPage = ({data}) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY} useRecaptchaNet scriptProps={{ async: true }}>
            <FormSubWebPageComponent data={data} />
        </GoogleReCaptchaProvider>
    )
}

const FormSubWebPageComponent = ({data}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const actionData = useActionData();
    const navigation = useNavigation();
    const {pathname} = useLocation();

    const [token, setToken] = useState('');
    const disabled = navigation?.state !== 'idle' || !token;

    useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }

        const handleReCaptchaVerify = async () => {
            return await executeRecaptcha('register')
        };

        setTimeout(() => {
            handleReCaptchaVerify().then(res => {
                setToken(res)
            }).catch(err => {
                // Catch
            });
        }, [1000]);

    }, [executeRecaptcha])

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; {data.title}</title>
            </Helmet>

            <StyledFormSubWebPage>
                <ColumnContainer>
                    <Heading1 $center>{data.title}</Heading1>

                    <Content>
                        <Text>{data.text}</Text>

                        <FormWrapper>
                            <StyledHeading3>Aanmeldformulier SOLS lidmaatschap</StyledHeading3>
                            {actionData?.success ? (
                                <Text>Het formulier is succesvol verzonden.</Text>
                            ) : (
                                <Form method="POST" action={pathname}>
                                    <FormField id="firstName" label="Voornaam" type="text" name="firstName" error={actionData?.errors?.firstName} />
                                    <FormField id="surname" label="Achternaam" type="text" name="surname" error={actionData?.errors?.surname} />
                                    <FormField id="dateOfBirth" label="Geboortedatum" type="date" name="dateOfBirth" error={actionData?.errors?.dateOfBirth} />
                                    <FormField id="arrivalYear" label="Aankomstjaar SSR" type="number" name="arrivalYear" error={actionData?.errors?.arrivalYear} />
                                    <FormField id="society" label="Dispuut" type="text" name="society" error={actionData?.errors?.society} />
                                    <FormField id="address" label="Adres" type="text" name="address" error={actionData?.errors?.address} />
                                    <FormField id="phoneNumber" label="Telefoonnummer" type="text" name="phoneNumber" error={actionData?.errors?.phoneNumber} />
                                    <FormField id="email" label="E-mailadres" type="email" name="email" error={actionData?.errors?.email} />
                                    <FormField id="collaboration" label="Wil je eventueel meewerken of advies geven over specifieke onderwerpen, zoals carrière?" type="radio" name="collaboration" options={[{id: "yes", value: "true", label: "Ja"}, {id: "no", value: "false", label: "Nee"}]} error={actionData?.errors?.collaboration} $isLast />

                                    <input type="hidden" name="token" value={token} onChange={e => setToken(e.target.value)} />

                                    {actionData?.errors?.unknown && <Error>{actionData?.errors?.unknown}</Error>}

                                    <SubmitButton type="submit" name="intent" value="sols" disabled={disabled}>Versturen</SubmitButton>
                                </Form>
                            )}
                        </FormWrapper>
                    </Content>
                </ColumnContainer>
            </StyledFormSubWebPage>
        </>
    );
}

export default FormSubWebPage;