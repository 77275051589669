import styled, {css} from 'styled-components';
import {marginMixin, truncateMixin} from '../Utils/Utils';

export const textStyles = css`
    font-size: var(--fs-body);
    color: ${({$white}) => $white ? 'var(--color-white);' : 'var(--color-dark);'};
    margin: 0;
    ${({ $truncate }) => $truncate && truncateMixin};
    ${({ $center }) => $center && 'text-align: center;'};
    ${({ $preWrap }) => $preWrap && 'white-space: pre-wrap;'};
    ${({ $mt, $mr, $mb, $ml }) => ($mt || $mr || $mb || $ml) && marginMixin};
`;

export const Text = styled.p`
    ${textStyles};
`;

export const HTMLText = styled.div`
    ${textStyles};
    
    h2, h3 {
        color: ${({$white}) => $white ? 'var(--color-white);' : 'var(--color-dark);'};
        font-family: var(--font-heading);
        line-height: 1.25;
        margin: 0;
        overflow-wrap: anywhere;
    }
    
    h2 {
        font-size: var(--fs-heading-2);
        font-weight: var(--fw-semi-bold);
    }
    
    h3 {
        font-size: var(--fs-heading-3);
        font-weight: var(--fw-semi-bold);
        margin-bottom: 5px;
    }

    p { margin: 0 0 16px 0; }

    ul, ol {
        display: flex;
        flex-direction: column;
    }

    li { margin: 0; }
    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    
    a {
        text-decoration: none;
        color: var(--color-primary);
        font-weight: var(--fw-bold);
        border-bottom: 3px solid ${({$elcid}) => $elcid ? 'var(--color-primary)' : 'var(--color-secondary)'};
        transition: .2s ease border-bottom;
        
        &:hover {
            border-color: var(--color-primary);
        }
    }

    & > *:first-child { margin-top: 0; }
    & > *:last-child { margin-bottom: 0; }
`;
