import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import {ApiFailureFallbackLayout} from '../Root/Root';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Container} from '../../components/Utils/Utils';
import styled from 'styled-components';
import {Button} from '../../components/Button/Button';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledErrorPage = styled.div`
    margin-top: 50px;
    margin-bottom: 125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const StyledText = styled(Text)`
    margin-top: 20px;
    margin-bottom: 30px;
    
    @media screen and (max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 20px;
    }
`;

const ErrorPage = () => {
    const { inElcidMode } = useInElcidMode();

    const error = useRouteError();

    let title = "Pagina niet gevonden";
    let description = "De pagina kon niet gevonden worden."

    if(isRouteErrorResponse(error)) {
        title = error.status === 404 ? "Pagina niet gevonden" : "Er is iets fout gegaan";
        description = error.status === 404 ? "De pagina kon niet gevonden worden." : "Er is een fout opgetredenm, probeer het opnieuw.";
    }

    return (
        <div>
            <Helmet>
                <title>SSR Leiden &mdash; {title}</title>
            </Helmet>

            <StyledErrorPage>
                <Container>
                    <Heading1>{title}</Heading1>
                    <StyledText>{description}</StyledText>
                    <Button to="/" $variant={inElcidMode ? "elcidOnBackground" : "primary"}>Terug naar home</Button>
                </Container>
            </StyledErrorPage>
        </div>
    );
}

export const ApiFailureFallback = () => {
    return (
        <ApiFailureFallbackLayout>
            <ErrorPage />
        </ApiFailureFallbackLayout>
    );
}

export default ErrorPage;