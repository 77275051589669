import {Form, Link, useActionData, useNavigation} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import styled from 'styled-components';
import {Container} from '../../components/Utils/Utils';
import {Text, textStyles} from '../../components/Text/Text';
import {FormField} from '../../components/FormField/FormField';
import {SubmitButton} from '../../components/Button/Button';
import {Heading1, Heading2, Heading3} from '../../components/Heading/Heading';
import React, {useEffect, useState} from 'react';
import {ReactComponent as Decoration} from '../../assets/illustrations/illustration.svg';
import {IconButton} from '../../components/IconButton/IconButton';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useInElcidMode} from '../../hooks/useInElcidMode';

const StyledContact = styled.div`
    margin-top: 50px;
    margin-bottom: -125px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 125px;
    }

    @media screen and (max-width: 475px) {
        margin-top: 0;
    }
`;

const Wrapper = styled.div`
    position: relative;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 186px 1fr;
    
    @media screen and (max-width: 1085px) {
        grid-template-rows: 126px 1fr;
    }
    
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        padding-top: 0;
        grid-template-rows: auto auto;
        width: 100vw;
        margin-left: -20px;
    }
`;

const Background = styled.section`
    background-color: var(--color-primary);
    padding: 50px 50px 64px;
    border-radius: 30px;
    grid-row: 2;
    grid-column: span 3 / -1;
    overflow: hidden;
    
    @media screen and (max-width: 1024px) {
        padding: 40px;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 20px 90px;
        grid-row: 1;
        border-radius: 0;
    }
`;

const FormWrapper = styled.article`
    padding: 40px;
    border-radius: 20px;
    background-color: ${({$elcid}) => $elcid ? 'var(--color-elcid-background)' : 'var(--color-background-60)'};
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column-start: 2;
    position: relative;
    margin: auto 60px 60px 0;
    ${({$success}) => $success && `min-height: 617px;`};
    
    @media screen and (max-width: 1024px) {
        padding: 30px;
        margin: auto 40px 40px 0;
    }

    @media screen and (max-width: 768px) {
        padding: 25px 25px 30px;
        grid-row: 2;
        grid-column: span 3 / -1;
        margin: -60px 20px 20px;
        ${({$success}) => $success && `min-height: unset;`};
    }
`;

const StyledHeading3 = styled(Heading3)`
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        margin-bottom: 12px;
    }
`;

const Inner = styled.div`
    position: relative;
    width: 45%;
    
    ${Heading2} { margin-bottom: 20px; }
    ${Text} {
        &:nth-of-type(1), &:nth-of-type(2) { margin-bottom: 20px; }
        &:nth-of-type(3) { margin-bottom: 14px; }
        &:nth-of-type(4) { margin-bottom: 30px; }
    }
    
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const StyledLink = styled(Link)`
    display: inline-block;
    color: inherit;
    font-weight: var(--fw-bold);
    text-decoration: none;
    position: relative;
    white-space: nowrap;

    &:after {
        content:'';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -2px;
        left: 0;
        border-radius: 2px;
        background-color: var(--color-secondary);
        transform: scaleX(1);
        transform-origin: bottom left;
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform-origin: bottom right;
            transform: scaleX(0);
        }
    }
`;

const StyledDecoration = styled(Decoration)`
    position: absolute;
    right: -30px;
    bottom: -30px;
    width: 315px;
    height: auto;
    transform: rotate(-20deg);
    
    path {
        fill: rgba(255, 255, 255, .05);
    }
`;

const IconButtons = styled.div`
    display: flex;
    gap: 20px;
`;

const Error = styled.p`
    ${textStyles};
    color: var(--color-error);
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        margin-bottom: 22px;
    }
`;

export async function contactAction({request}) {
    const formData = await request.formData();

    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');
    const token = formData.get('token');

    // Validate formData
    const errors = {};

    if (typeof name !== 'string' || !name.length > 0) {
        errors.name = 'Vul een naam in';
    }

    if (typeof email !== 'string' || !email.includes('@') || !email.length > 0) {
        errors.email = 'Vul een geldig e-mailadres in';
    }

    if (typeof message !== 'string' || !message.length > 0) {
        errors.message = 'Vul een bericht in';
    }

    // Return data if we have errors
    if (Object.keys(errors).length) {
        return {"errors": errors};
    }

    const body = {
        "name": name,
        "email": email,
        "message": message,
        "token": token
    }

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/website/contact`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
        signal: request.signal
    }).then(response => {
        if(!response.ok) {
            return response.json().then((errorResponse) => {
                throw errorResponse;
            });
        }

        return response.json();
    }).then(result => {
        return {"success": true};
    }).catch(error => {
        let formattedError = {};

        // Format the error object the same as client side validation
        if(error?.fields) {
            if(Array.isArray(error?.fields)) {
                error?.fields?.forEach((err) => {
                    formattedError[err] = "Het veld is verplicht";
                });
            } else {
                Object.entries(error?.fields)?.forEach(entry => {
                    const [key, value] = entry;
                    formattedError[key] = value;
                })
            }
        } else {
            formattedError.unknown = "Er is een fout opgetreden, probeer het opnieuw.";
        }

        return {
            "errors": formattedError
        };
    })
}

const Contact = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY} useRecaptchaNet scriptProps={{ async: true }}>
            <ContactPage />
        </GoogleReCaptchaProvider>
    );
}

const ContactPage = () => {
    const { inElcidMode } = useInElcidMode();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const actionData = useActionData();
    const navigation = useNavigation();

    const [token, setToken] = useState('');
    const disabled = navigation?.state !== 'idle' || !token;

    useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }

        const handleReCaptchaVerify = async () => {
            return await executeRecaptcha('contact')
        };

        setTimeout(() => {
            handleReCaptchaVerify().then(res => {
                setToken(res)
            }).catch(err => {
                // Catch
            });
        }, [1000]);

    }, [executeRecaptcha])

    return (
        <>
            <Helmet>
                <title>SSR Leiden &mdash; Contact</title>
            </Helmet>

            <StyledContact>
                <Container>
                    <Heading1 $center>Contact</Heading1>

                    <Wrapper>
                        <Background>
                            <Inner>
                                <Heading2 $white>Vind ons in Leiden</Heading2>
                                <Text $white>SSR-Leiden<br/>Hogewoerd 108 <br/>2311 HT Leiden</Text>
                                <Text $white>KvK-nummer: 40445806</Text>
                                <Text $white>
                                    {inElcidMode ? (
                                        <StyledLink to="mailto:elcidx@ssr-leiden.nl">elcidx@ssr-leiden.nl</StyledLink>
                                    ) : (
                                        <StyledLink to="mailto:bestuur@ssr-leiden.nl">bestuur@ssr-leiden.nl</StyledLink>
                                    )}
                                </Text>
                                <Text $white><StyledLink to="tel:0715120900">071-5120900</StyledLink></Text>

                                <IconButtons>
                                    <IconButton icon="instagram" to="https://www.instagram.com/ssr_leiden/" $onPrimary />
                                    <IconButton icon="facebook" to="https://www.facebook.com/SSRLeiden/" $onPrimary />
                                    <IconButton icon="linkedin" to="https://www.linkedin.com/company/ssr-leiden" $onPrimary />
                                    <IconButton icon="twitter" to="https://twitter.com/ssrleiden" $onPrimary />
                                </IconButtons>
                                <StyledDecoration />
                            </Inner>
                        </Background>

                        <FormWrapper $success={actionData?.success} $elcid={inElcidMode}>
                            <StyledHeading3>Neem contact met ons op</StyledHeading3>
                            {actionData?.success ? (
                                <Text>Het formulier is succesvol verzonden.</Text>
                            ) : (
                                <Form method="POST">
                                    <FormField id="name" label="Naam" type="text" name="name" error={actionData?.errors?.name} />
                                    <FormField id="email" label="E-mailadres" type="email" name="email" error={actionData?.errors?.email} />
                                    <FormField id="message" label="Bericht" type="textarea" name="message" rows="5" error={actionData?.errors?.message} $isLast />

                                    <input type="hidden" name="token" value={token} onChange={e => setToken(e.target.value)} />

                                    {actionData?.errors?.unknown && <Error>{actionData?.errors?.unknown}</Error>}

                                    <SubmitButton type="submit" disabled={disabled} $variant={inElcidMode ? "elcidOnBackground" : "primary"}>Versturen</SubmitButton>
                                </Form>
                            )}
                        </FormWrapper>
                    </Wrapper>
                </Container>
            </StyledContact>
        </>
    );
}

export default Contact;